/* fonts */
export const FontFamily = {
    titleH3MontserratRegular28: "Montserrat_regular",
    titleH5MontserratSemibold20: "Montserrat_semibold",
    labelAidTextMontserratBold12: "Montserrat_bold",
    notoSerifBlack: "Noto Serif_black",
    montserratMedium: "Montserrat_medium",
    montserratMediumItalic: "Montserrat_medium_italic",
    sFProTextSemibold: "SF Pro Text_semibold",
  };
  /* font sizes */
  export const FontSize = {
    labelAidTextMontserratBold12_size: 12,
    size_sm: 14,
    labelAidTextMontserratRegular16_size: 16,
    size_lg: 17,
    size_xl: 18,
    titleH5MontserratSemibold20_size: 19, 
    //change back to 20 font size above!
    titleH4MontserratSemibold22_size: 22,
    size_4xl: 24,
    titleH3MontserratRegular28_size: 28,
    titleH1MontserratSemibold40_size: 40,
  };
  /* Colors */
  export const Color = {
    hiFiMainColorsLightBlue: "#eaf2f4",
    hiFiMainColorsDarkBlue: "#4a5f64",
    darkslategray_200: "#4a5f63",
    darkslategray_300: "#313f43",
    loFiBlack: "#292d32",
    hiFiBlackAndWhiteWhite: "#fafafa",
    gray_200: "rgba(0, 0, 0, 0)",
    hiFiDenotativeColorsSuccessGreen: "#75b733",
    hiFiMainColorsGreen: "#0d7e50",
    loFiGray4: "#707376",
    hiFiMainColorsCian: "#94bec7",
    hiFiDenotativeColorsDisabledGray: "#c6cdcf",
    hiFiBackgroundGradient: "linear-gradient(180deg, #C9DEE3 0%, #C9DEE3 21.88%, #94BEC7 100%)",
    errorRed: "#C54F56"
  };
  /* Paddings */
  export const Padding = {
    p_13xs: 1,
    p_12xs: 2,
    p_11xs: 3,
    p_10xs: 4,
    p_9xs: 5,
    p_8xs: 6,
    p_7xs: 7,
    p_6xs: 8,
    p_5xs: 9,
    p_4xs: 10,
    p_3xs: 11,
    p_2xs: 12,
    p_xs: 14,
    p_sm: 15,
    p_md: 16,
    p_lg: 17,
    p_xl: 18,
    p_2xl: 20,
    p_3xl: 23,
    p_4xl: 24,
    p_5xl: 29,
    p_6xl: 30,
    p_7xl: 32,
    p_8xl: 44,
    p_9xl: 49,
    p_10xl: 58,
    p_11xl: 71,
    p_12xl: 72,
    p_13xl: 118,
  };
  /* Margins */
  export const Margin = {
    m_12xs: -24,
    m_11xs: 0,
    m_10xs: 1,
    m_9xs: 2,
    m_8xs: 3,
    m_7xs: 4,
    m_6xs: 5,
    m_5xs: 6,
    m_4xs: 7,
    m_3xs: 8,
    m_2xs: 9,
    m_xs: 10,
    m_sm: 11,
    m_md: 13,
    m_lg: 16,
    m_xl: 17,
    m_2xl: 19,
    m_3xl: 20,
    m_4xl: 21,
    m_5xl: 22,
    m_6xl: 24,
    m_7xl: 26,
    m_8xl: 28,
    m_9xl: 36,
    m_10xl: 37,
    m_11xl: 44,
    m_12xl: 79,
  };
  /* border radiuses */
  export const Border = {
    br_2xs: 3,
    br_xs: 8,
    br_sm: 10,
    br_md: 15,
    br_lg: 20,
    br_xl: 50,
    br_2xl: 51,
  };
  