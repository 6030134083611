import * as React from "react";
import { useContext, useEffect,useState } from "react";
import {
  ScrollView,
  Image,
  StyleSheet,
  Pressable,
  Text,
  View,
  TouchableOpacity,
  Alert
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import MasterButton from "../components/MasterButton";
import {
  Margin,
  FontFamily,
  Color,
  FontSize,
  Padding,
  Border,
} from "../GlobalStyles";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../utils/AppContext";
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { GetGameSessionQuery, CreatePlayerGameMutation, CreatePlayerGameInput, NameByEmailQuery, UpdateGameSessionInput, UpdateGameSessionMutation, GameStatus, CreateSMSMessageInput, SendSMSMutation  } from "../src/API";
import { getGameSession, nameByEmail } from "../src/graphql/queries";
import { createPlayerGame, updateGameSession, sendSMS } from "../src/graphql/mutations";
import PaymentCollection from '../components/PaymentCollection'


const OnboardingRules = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const {gameID, setGameID, email, setEmail, gameAmount, setGameAmount , payment, setPayment, playerID, setPlayerID} = useAppContext();

  let gameIDKey = sessionStorage.getItem('gameIDKey') ?? '';
  let playerIDKey = sessionStorage.getItem('playerIDKey') ?? '';
  // let nameKey = sessionStorage.getItem('nameKey') ?? '';
  // let phoneNumberKey = sessionStorage.getItem('phoneKey') ?? '';

  // // const { PlayerIDKey, GameIDKey } = location.state;
  // // setGameID(GameIDKey);
  // // setPlayerID(PlayerIDKey);
  // // const [playerID, setPlayerID] = useState("");

  // useEffect(() => {
  //   sendMessage()
  //   // setTimeout(sendMessage, 1500)
  // }, []);

  //   const sendMessage = async () => {
  //     try {
  //       const smsInput: CreateSMSMessageInput = {phoneNumber: phoneNumberKey, name: nameKey, winStatus: true}
  //       const createdSMS = await API.graphql<GraphQLQuery<SendSMSMutation>>(graphqlOperation(sendSMS, {input: smsInput}));
  //       console.log ("SMS function triggered: ", createdSMS.data?.sendSMS?.result);
  //     } catch (error) {
  //       console.error('Failed to send SMS', error);
  //     }
  //   }


  console.log("From rules page: ", gameID);
  console.log("From rules page: ", email);

  console.log("From rules page, the game amount: ", gameAmount);

  useEffect(() => {
    const url = window.location.href;
    const searchString = "redirect_status=succeeded";
    const startIndex = url.indexOf(searchString);

    console.log("Url index: ", startIndex);

    if (startIndex !== -1) {
      setPayment(true);
    } else {
      console.log("Payment not verified");
    }
  }, []); 
  

  // we need to pass the gameID and the email to this page to do the necessary queries, mutation updates
  // const getData = () => {
  //   let gameIDKey = sessionStorage.getItem('gameIDKey');
  //   let playerIDKey = sessionStorage.getItem('playerIDKey');

  //   return gameIDKey;
  // };

  //   useEffect(() => {
  //   getData()
  // }, []);

  console.log ("From Session, game ID: ", gameIDKey);
  console.log ("From Session, player ID: ", playerIDKey);

  // useEffect(() => {
  //   getGameDetails()
  // }, []);

  // const getGameDetails = async () => {
  //   try {
  //     const getGameDetails = await API.graphql<GraphQLQuery<GetGameSessionQuery>>(graphqlOperation(getGameSession, { id: gameID }))
  //     if(getGameDetails){
  //       const gameStatus = getGameDetails.data?.getGameSession?.status;
  //       console.log("Game status from mutation: ",gameStatus);
  //       const amountPerUser = getGameDetails.data?.getGameSession?.amountPerPlayer;
  //       if(amountPerUser){
  //         setGameAmount(amountPerUser);
  //       }
  //       console.log("Game amount from mutation: ",gameAmount);
  //     }

  //   } catch (error) {
  //     console.log ("There has been an error", error);
  //   }
  // }
  // const user = await Auth.currentAuthenticatedUser();

  // useEffect(() => {
  //   setGameID(localStorage.getItem('gameIDkey') ?? '');
  //   setEmail(localStorage.getItem('emailKey') ?? '');
  //   setPlayerID(localStorage.getItem('PlayerIDKey') ?? '')
  // }, []); 

  // useEffectOnce(() => {
  //   setGameID(localStorage.getItem('gameIDkey') ?? '');
  //   setEmail(localStorage.getItem('emailKey') ?? '');
  //   setPlayerID(localStorage.getItem('PlayerIDKey') ?? '')
  // }); 

  // useEffect(() => {
  //   setGameID(GameIDKey);
  //   setPlayerID(PlayerIDKey);
  // }); 

  // async function getID () {
  //   const existingPlayer = await API.graphql<GraphQLQuery<NameByEmailQuery>>(
  //     graphqlOperation(nameByEmail, { email: email })
  //     )
  //   const userID = existingPlayer.data?.nameByEmail?.items[0]?.id
  //   if (userID != null){
  //     setPlayerID(userID);
  //   }
  //   console.log("Player ID: ", playerID);  
  // }

  // const getID = async () => {
  //   try {
  //     const existingPlayer = await API.graphql<GraphQLQuery<NameByEmailQuery>>(
  //       graphqlOperation(nameByEmail, { email: email })
  //       )
  //     const userID = existingPlayer.data?.nameByEmail?.items[0]?.id
  //     if (userID != null){
  //       setPlayerID(userID);
  //     }
  //     console.log("Player ID: ", playerID);  
      
  //   } catch (error) {
  //     console.log("Error: ", error);
  //   }
  // }

  // useEffect( () => {
  //   // Need to do a query against the nameByEmail for current user, to retrieve their playerID or get it and pass it through the routes to be used
  //   // here
  //   getID();
  // });

  // useEffect(() => {

  //   AsyncStorage.getItem('gameData').then((data) => {
  //     if (data) {
  //       const parsedData = JSON.parse(data);
  //       console.log("parsed data: ", parsedData);
  //       setGameID(parsedData.gameIDkey);
  //       setEmail(parsedData.emailKey);
  //     }
  //   });
  // }, []); 






  // need to do a createplayergame mutation after successful payment like in the main app
  // 
    const nextStep = async () => {
      try {
        const playerGame: CreatePlayerGameInput = {gameSessionId: gameIDKey, playerId: playerIDKey}
        const createdPlayerGame = await API.graphql<GraphQLQuery<CreatePlayerGameMutation>>(graphqlOperation(createPlayerGame, {input: playerGame}));
        console.log("Created Player game connection: ", createdPlayerGame);
        
        const newSession: UpdateGameSessionInput = { id: gameIDKey, status: GameStatus.ONBOARDING }
        const updatedGameSession = await API.graphql<GraphQLQuery<UpdateGameSessionMutation>>(graphqlOperation(updateGameSession, {input: newSession}));
        const newStatus = updatedGameSession.data?.updateGameSession?.status;
        console.log("Updated game status: ", newStatus);

        navigate("/game");

        Alert.alert("Joining the game");
        
      } catch (error) {
        console.log('error creating player game connection', error);
        Alert.alert("There has been an error in joining the game");
      }
    }


    // setPayment(true);
 
  return (
    <LinearGradient
      style={styles.onboardingrules}
      locations={[0, 0.22, 1]}
      colors={["#c9dee3", "#c9dee3", "#94bec7"]}
    >
      <ScrollView
        style={styles.iphoneTopPropertiesParent}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <Image
          style={styles.iphoneTopProperties}
          resizeMode="cover"
          // source={require("../assets/iphone-top-properties5.png")}
        />
        <View style={[styles.frameParent, styles.mt1]}>
          <View style={styles.howToPlayWrapper}>
            <Text style={[styles.howTypo, styles.textTypo]}>How to play:</Text>
          </View>
          <View style={styles.numberPad5Parent}>
            <View style={styles.numberPad5Layout}>
              <View style={[styles.ellipseParent, styles.numberPad5Layout]}>
                <Image
                  style={[styles.ellipseParent, styles.numberPad5Layout]}
                  resizeMode="cover"
                  source={require("../assets/ellipse-23.png")}
                />
                <Text style={[styles.text, styles.textTypo]}>1</Text>
              </View>
            </View>
            <Text
              style={[
                styles.selectHowMany,
                styles.ml10,
                styles.howTypo,
                styles.selectHowManyTypo,
              ]}
            >
              Select how many people will be joining you, how long you will be
              present, and how much your time is worth.
            </Text>
            <View style={[styles.frameChild, styles.ml10]} />
          </View>
          <View style={styles.numberPad5Parent}>
            <View style={styles.numberPad5Layout}>
              <Image
                style={[styles.ellipseParent, styles.numberPad5Layout]}
                resizeMode="cover"
                source={require("../assets/ellipse-23.png")}
              />
              <Text style={[styles.text, styles.textTypo]}>2</Text>
            </View>
            <Text
              style={[
                styles.selectHowMany,
                styles.ml10,
                styles.howTypo,
                styles.selectHowManyTypo,
              ]}
            >
              Invite friends to be present with you by having them scan your QR
              code.
            </Text>
            <View style={[styles.frameChild, styles.ml10]} />
          </View>
          <View style={styles.numberPad5Parent}>
            <View style={styles.numberPad5Layout}>
              <Image
                style={[styles.ellipseParent, styles.numberPad5Layout]}
                resizeMode="cover"
                source={require("../assets/ellipse-23.png")}
              />
              <Text style={[styles.text, styles.textTypo]}>3</Text>
            </View>
            <Text
              style={[
                styles.selectHowMany,
                styles.ml10,
                styles.howTypo,
                styles.selectHowManyTypo,
              ]}
            >
              Don’t touch your phone for the length of the game and the
              winner(s) will be rewarded for being present!
            </Text>
            <View style={[styles.frameChild, styles.ml10]} />
          </View>
          <View style={styles.largeButtonsNoIconsWrapper}>
          {payment ? (
                      <Pressable
                        style={[
                          styles.largeButtonsNoIcons,
                          styles.socialWithoutTheFlexBox,
                        ]}
                        onPress={nextStep}
                      >
                        <MasterButton
                          buttonText="Join"
                          masterButtonHeight={56}
                          buttonTextDisplay="flex"
                          buttonTextAlignItems="center"
                          buttonTextJustifyContent="center"
                          buttonTextWidth={119}
                          // iconshome={require("../assets/iconshome19.png")}
                          // iconshome1={require("../assets/iconshome19.png")}
                        />
                      </Pressable>      
                ) : 
                (
                  <PaymentCollection />
                )}
          </View>
        </View>
      </ScrollView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  mt48: {
    marginTop: 77,
  },
  mt36: {
    marginTop: Margin.m_9xl,
  },
  ml10: {
    marginLeft: Margin.m_xs,
  },
  mt1: {
    marginTop: Margin.m_10xs,
  },
  frameScrollViewContent: {
    flexDirection: "column",
  },
  socialWithoutTheFlexBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  selectHowManyTypo: {
    display: "flex",
    fontWeight: "500",
  },
  textTypo: {
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    fontWeight: "600",
  },
  numberPad5Layout: {
    height: 32,
    width: 32,
  },
  howTypo: {
    textAlign: "left",
    color: Color.darkslategray_300,
    lineHeight: 18,
    fontSize: FontSize.size_sm,
  },
  iphoneTopProperties: {
    width: 375,
    height: 44,
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  wrapper: {
    width: 216,
    height: 216,
  },
  socialWithoutThe: {
    fontSize: FontSize.size_xl,
    lineHeight: 23,
    fontStyle: "italic",
    fontFamily: FontFamily.montserratMediumItalic,
    width: 310,
    textAlign: "center",
    color: Color.hiFiMainColorsDarkBlue,
    justifyContent: "center",
    display: "flex",
    fontWeight: "500",
  },
  vectorParent: {
    height: 341,
    paddingHorizontal: Padding.p_7xl,
    paddingVertical: Padding.p_3xl,
    alignItems: "center",
    alignSelf: "stretch",
    overflow: "hidden",
  },
  howToPlayWrapper: {
    paddingHorizontal: Padding.p_md,
    paddingVertical: Padding.p_10xs,
    flexDirection: "row",
    overflow: "hidden",
  },
  ellipseParent: {
    top: 0,
    left: 0,
    position: "absolute",
  },
  text: {
    top: 7,
    left: 9,
    letterSpacing: 0,
    lineHeight: 18,
    width: 14,
    position: "absolute",
    fontSize: FontSize.size_sm,
    fontWeight: "600",
    textAlign: "center",
    color: Color.hiFiMainColorsDarkBlue,
  },
  selectHowMany: {
    fontFamily: FontFamily.montserratMedium,
    width: 275,
    display: "flex",
    fontWeight: "500",
    textAlign: "left",
    color: Color.darkslategray_300,
    lineHeight: 18,
    alignItems: "center",
  },
  frameChild: {
    overflow: "hidden",
  },
  numberPad5Parent: {
    height: 88,
    paddingHorizontal: Padding.p_4xs,
    paddingTop: Padding.p_xs,
    alignSelf: "stretch",
    flexDirection: "row",
    overflow: "hidden",
  },
  largeButtonsNoIcons: {
    alignSelf: "stretch",
    flexDirection: "row",
  },
  largeButtonsNoIconsWrapper: {
    height: 112,
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_4xs,
    alignSelf: "stretch",
    overflow: "hidden",
  },
  frameParent: {
    borderTopLeftRadius: Border.br_md,
    borderTopRightRadius: Border.br_md,
    backgroundColor: Color.hiFiMainColorsLightBlue,
    paddingBottom: Padding.p_3xl,
    alignSelf: "stretch",
    flex: 1,
  },
  iphoneTopPropertiesParent: {
    alignSelf: "stretch",
    overflow: "hidden",
    flex: 1,
  },
  onboardingrules: {
    height: 812,
    backgroundColor: Color.hiFiBackgroundGradient,
    flexDirection: "row",
    overflow: "hidden",
    width: "100%",
    flex: 1,
  },
  getReady: {
    color: Color.hiFiMainColorsDarkBlue,
    textAlign: "left",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    fontWeight: "600",
    fontSize: FontSize.titleH3MontserratRegular28_size,
  },
  phoneDownIn: {
    display: "flex",
    justifyContent: "center",
    width: 289,
    fontSize: FontSize.titleH3MontserratRegular28_size,
    textAlign: "center",
    color: Color.darkslategray_300,
    fontFamily: FontFamily.titleH3MontserratRegular28,
    alignItems: "center",
  },
  phoneDownInTypo: {
    textAlign: "center",
    color: Color.darkslategray_300,
    fontFamily: FontFamily.titleH3MontserratRegular28,
  },
  getReadyParent: {
    paddingVertical: Padding.p_sm,
    alignItems: "center",
    paddingHorizontal: Padding.p_8xl,
    alignSelf: "stretch",
    overflow: "hidden",
  },
});

export default OnboardingRules;


