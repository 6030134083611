import React, { useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';

const CustomSpinner = () => {
  // const rotation = useSharedValue(0);
  // const animatedStyles = useAnimatedStyle(() => {
  //   return {
  //     transform: [
  //       {
  //         rotateZ: `${rotation.value}deg`,
  //       },
  //     ],
  //   };
  // }, [rotation.value]);

  // useEffect(() => {
  //   rotation.value = withRepeat(
  //     withTiming(360, {
  //       duration: 8000,
  //       easing: Easing.linear,
  //     }),
  //     200
  //   );
  //   return () => cancelAnimation(rotation);
  // }, []);

  return (
    <View style={styles.container}>
      <View style={[styles.spinner]} />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  spinner: {
    height: 260,
    width: 260,
    borderRadius: 130,
    borderWidth: 20,
    borderTopColor: '#rgba(166, 201, 208, 1)',
    borderRightColor: 'rgba(166, 201, 208, 1)',
    borderBottomColor: 'rgba(166, 201, 208, 1)',
    borderLeftColor: 'rgba(123, 158, 166, 1)',
    backgroundColor: '#EAF2F4',
    shadowColor:'white',
    shadowOffset:{width: 260, height:260},
  },
});
export default CustomSpinner;