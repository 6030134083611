import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import {
  StatusBar,
  Image,
  StyleSheet,
  ScrollView,
  Text,
  View,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import {
  Margin,
  Color,
  FontFamily,
  FontSize,
  Padding,
  Border,
} from "../GlobalStyles";
import CustomSpinner from "../components/spinner";
import { Accelerometer } from "expo-sensors";
import { useAppContext } from "../utils/AppContext";
import { API, graphqlOperation, Hub } from "aws-amplify";
import { GraphQLQuery } from '@aws-amplify/api';
import { GraphQLSubscription } from '@aws-amplify/api';
import * as subscriptions from '../src/graphql/subscriptions';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { useNavigate, useLocation } from "react-router-dom";
import { OnUpdateGameSessionSubscriptionVariables, OnUpdateGameSessionSubscription, GetGameSessionQuery, UpdateGameSessionInput, UpdateGameSessionMutation } from "../src/API";
import { getGameSession } from "../src/graphql/queries";
import { updateGameSession } from "../src/graphql/mutations";



const Game = () => {
  // const navigation = useNavigation();
  // const isFocused = useIsFocused();

  // need to do a subscription here to know when the game status changes to Active
  // when it does, go from Waiting for game to start to the normal countdown

  // then do the same logic we had from the app, for seeing if a user wins or not

  //getUser details via their email they used, or pass the email.
  // if condition of if the email does not exist in the game players, there is an issue

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // const {gameID, email, gameDuration} = useAppContext();

  let gameID = sessionStorage.getItem('gameIDKey') ?? '';
  let email = sessionStorage.getItem('emailKey') ?? '';
  let gameDurationString = sessionStorage.getItem('gameDurationKey') ?? '';
  let gameDuration = parseInt(gameDurationString);


  // the issue is here, gameDuration is being reset to 0 from the previous screens, need to pass
  // as sessionStorage, else it is using 0 and automatically going to the win screen.

  const [countdown, setCountdown] = useState(5);
  const [timer, setTimer] = useState(gameDuration * 60); 
  const [showTimer, setShowTimer] = useState(false);
  const [gameWait, setGameWait] = useState(true);
  const [accelerometerData, setAccelerometerData] = useState<{
    x: number;
    y: number;
    z: number;
  } | null>(null);

  // useEffect(()=>{
  //   const variables: OnUpdateGameSessionSubscriptionVariables={
  //     filter: {
  //       // Only receive gameUpdate messages for this gameID
  //       id: { eq: gameID }       
  //     }
  //   } 
  //   // sub.unsubscribe();
  //   // sub();
  //   Hub.listen('api', (data: any) => {
  //     const { payload } = data;
  //     if (payload.event === CONNECTION_STATE_CHANGE) {
  //       const connectionState = payload.data.connectionState as ConnectionState;
  //       console.log(connectionState);
  //     }
  //   });

  //   const sub =  API.graphql<GraphQLSubscription<OnUpdateGameSessionSubscription>>(
  //     graphqlOperation(subscriptions.onUpdateGameSession, variables)
  //   ).subscribe({
  //     next: (response)  => {
  //       if (response){
  //         const status = response.value.data?.onUpdateGameSession?.status;
  //         console.log("Current status: ", status);
  //         // subscription is established
  //         if(status === "ACTIVE"){
  //           setGameWait(false);
  //           sub.unsubscribe();
  //         } 
  //       }
  //     }   
  //   });
  // });


    const variables: OnUpdateGameSessionSubscriptionVariables={
      filter: {
        // Only receive gameUpdate messages for this gameID
        id: { eq: gameID }       
      }
    } 
    // sub.unsubscribe();
    // sub();

    let priorConnectionState: ConnectionState;

    Hub.listen("api", (data: any) => {
      const { payload } = data;
      if (
        payload.event === CONNECTION_STATE_CHANGE
      ) {

        if (priorConnectionState === ConnectionState.Connecting && payload.data.connectionState === ConnectionState.Connected) {
        }
        priorConnectionState = payload.data.connectionState;
      }
    });

    const statusSub =  API.graphql<GraphQLSubscription<OnUpdateGameSessionSubscription>>(
      graphqlOperation(subscriptions.onUpdateGameSession, variables)
    ).subscribe({
      next: (response)  => {
        if (response){
          const status = response.value.data?.onUpdateGameSession?.status;
          console.log("Current status: ", status);
          // subscription is established
          if(status === "ACTIVE"){
            setGameWait(false);
          } else if( gameWait != true){

          // we need to unsubscribe here
            cleanupSubscriptions();
          } 
        }
      }   
    });

    const cleanupSubscriptions = () => {
      statusSub.unsubscribe();
    }

  
  useEffect(() => {
    if(gameWait != true){
      cleanupSubscriptions()
      const intervalId = setInterval(() => {
        setCountdown((countdown) => {
          if (countdown === 1) {
            clearInterval(intervalId);
            setShowTimer(true);
          }
          return countdown - 1;
        });
      }, 1000);
  
      return () => clearInterval(intervalId);
    }
  }, [gameWait]);

  useEffect(() => {
    if (showTimer) {
      const intervalId = setInterval(() => {
        setTimer((timer) => {
          if (timer <= 0) {
            clearInterval(intervalId);
            return 0;
          } else {
            return timer - 1;
          }
        });
      }, 1000);

      const subscription = Accelerometer.addListener((accelerometerData) => {
        setAccelerometerData(accelerometerData);
      });

      return () => {
        clearInterval(intervalId);
        subscription.remove();
        cleanupSubscriptions();
      };
    }
  }, [showTimer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  // real version
useEffect(() => {
  switch (true) {
    case timer !== 0 && accelerometerData && accelerometerData.y < -0.15:
      addLoser();
      navigate("/loss");
      break;
    case (timer === 0  && currentPath !== "/loss"):
      addWinner();
      navigate("/won");
      break;
  }
}, [accelerometerData, timer, navigate]);


// for testing, to be deleted below
// useEffect(() => {
//   switch (true) {
//     case timer !== 0:
//       addLoser();
//       navigate("/loss");
//       break;
//     case (timer === 0 && currentPath !== "/loss"):
//       addLoser();
//       navigate("/loss");
//       // addWinner();
//       // navigate("/won");
//       break;
//   }
// }, [accelerometerData, timer, navigate]);

const addLoser = async () =>{
  try {
    const getGameDetails = await API.graphql<GraphQLQuery<GetGameSessionQuery>>(graphqlOperation(getGameSession, { id: gameID }))
    const currentLosers = getGameDetails.data?.getGameSession?.losers;
    console.log("The current Losers: ", currentLosers);
    if(currentLosers === null) {
      // run a async function that does a mutation and pushes to the array, this being the first name
      const updatedLosers = [email];
      const newSession: UpdateGameSessionInput = { id: gameID, losers: updatedLosers}
      const updatedGameSession = await API.graphql<GraphQLQuery<UpdateGameSessionMutation>>(graphqlOperation(updateGameSession, {input: newSession}));
    } else if (currentLosers != null) {
      // run a async function that does a mutation and pushes to the array, establish the new player, and
      // update current players
      const newLoser = email;
      const updatedLosers = [...currentLosers, newLoser]
      const newSession: UpdateGameSessionInput = { id: gameID, losers: updatedLosers}
      const updatedGameSession = await API.graphql<GraphQLQuery<UpdateGameSessionMutation>>(graphqlOperation(updateGameSession, {input: newSession}));
    }   
  } catch (error) {
    console.log("Error message: ", error);
  }
}

const addWinner = async () =>{
  try {
    const getGameDetails = await API.graphql<GraphQLQuery<GetGameSessionQuery>>(graphqlOperation(getGameSession, { id: gameID }))
    const currentWinners = getGameDetails.data?.getGameSession?.winners;
    console.log("The current Winners: ", currentWinners);
    if(currentWinners === null) {
      // run a async function that does a mutation and pushes to the array, this being the first name
      const updatedWinners = [email];
      const newSession: UpdateGameSessionInput = { id: gameID, initiator: email, winners: updatedWinners}
      const updatedGameSession = await API.graphql<GraphQLQuery<UpdateGameSessionMutation>>(graphqlOperation(updateGameSession, {input: newSession}));
    } else if (currentWinners != null) {
      // run a async function that does a mutation and pushes to the array, establish the new player, and
      // update current players
      const newWinner = email;
      const updatedWinners = [...currentWinners, newWinner]
      const newSession: UpdateGameSessionInput = { id: gameID, initiator: email, winners: updatedWinners}
      const updatedGameSession = await API.graphql<GraphQLQuery<UpdateGameSessionMutation>>(graphqlOperation(updateGameSession, {input: newSession}));
    }
  } catch (error) {
    console.log("Error message: ", error);
  }
}


// subscription on game state, for until game goes to Active, (set by main app user)
// show the waiting for the game to start before the countdown, and a setVariable true
// when true, it shows the rest of the screen

// 

  return (       
    <LinearGradient
      style={[styles.playersCountdown7, styles.playersCountdown7FlexBox]}
      locations={[0, 0.22, 1]}
      colors={["#c9dee3", "#c9dee3", "#94bec7"]}
    >
      <View style={[styles.playersCountdown7FlexBox, styles.iphoneTopPropertiesParent]}>
        <ScrollView
          style={[styles.frameParent, styles.mt13, styles.frameParentFlexBox]}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.frameScrollViewContent}
        >
       {gameWait ? (
          <View style={styles.getReadyParent}>
            <Text style={styles.getReady}>Waiting for Game to start</Text>
      
            <Text style={[styles.phoneDownIn, styles.mt48, styles.phoneDownInTypo]}>
                    The game will start shortly
              </Text>
          </View>
       ):(
          <><View style={styles.getReadyParent}>
                {countdown > 0 ? (
                  <Text style={styles.getReady}>Get Ready</Text>
                ) : (
                  <Text style={styles.getReady}>Present Mode</Text>
                )}

                {countdown > 0 ? (
                  <Text
                    style={[styles.phoneDownIn, styles.mt48, styles.phoneDownInTypo]}
                  >
                    Phone down in...
                  </Text>
                ) : (
                  <Text
                    style={[styles.phoneDownIn, styles.mt48, styles.phoneDownInTypo]}
                  >
                    Session In Progress
                  </Text>
                )}
              </View><View
                style={[
                  styles.countdownWrapper,
                  styles.mt21,
                  styles.frameParentFlexBox,
                ]}
              >
                  <View style={styles.countdown}>
                    <CustomSpinner />
                    {countdown > 0 && <Text style={styles.text}>{countdown}</Text>}
                    {showTimer && (
                      <Text style={styles.textTimer}>
                        {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                      </Text>
                    )}
                  </View>
                </View><View style={[styles.rememberIfYouTouchYourPhWrapper, styles.mt21]}>
                  <Text style={[styles.rememberIfYou, styles.phoneDownInTypo]}>
                    Remember, if you touch your phone from now on, you’ll lose!
                  </Text>
                </View></>
          )}   
        </ScrollView>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  mt48: {
    marginTop: 77,
  },
  mt21: {
    marginTop: Margin.m_4xl,
  },
  frameScrollViewContent: {
    flexDirection: "column",
  },
  mt13: {
    marginTop: Margin.m_md,
  },
  iphoneTopPropertiesParent: {
    flex: 1,
    marginTop: Margin.m_10xl
  },
  playersCountdown7FlexBox: {
    overflow: "hidden",
    flex: 1,
  },
  frameParentFlexBox: {
    alignSelf: "stretch",
    overflow: "hidden",
  },
  phoneDownInTypo: {
    textAlign: "center",
    color: Color.darkslategray_300,
    fontFamily: FontFamily.titleH3MontserratRegular28,
  },
  countdownPosition: {
    left: "0%",
    right: "0%",
    maxHeight: "100%",
    maxWidth: "100%",
    position: "absolute",
    overflow: "hidden",
    width: "100%",
  },
  getReady: {
    color: Color.hiFiMainColorsDarkBlue,
    textAlign: "left",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    fontWeight: "600",
    fontSize: FontSize.titleH3MontserratRegular28_size,
  },
  phoneDownIn: {
    display: "flex",
    justifyContent: "center",
    width: 289,
    fontSize: FontSize.titleH3MontserratRegular28_size,
    textAlign: "center",
    color: Color.darkslategray_300,
    fontFamily: FontFamily.titleH3MontserratRegular28,
    alignItems: "center",
  },
  getReadyParent: {
    paddingVertical: Padding.p_sm,
    alignItems: "center",
    paddingHorizontal: Padding.p_8xl,
    alignSelf: "stretch",
    overflow: "hidden",
  },
  countdownChild: {
    height: "104.6%",
    width: "138.46%",
    top: "-2.3%",
    right: "-19.23%",
    left: "-19.23%",
    maxHeight: "100%",
    maxWidth: "100%",
    bottom: "-2.3%",
    position: "absolute",
    overflow: "hidden",
  },
  countdownItem: {
    height: "99.62%",
    top: "0.38%",
    bottom: "0%",
    borderRadius: Border.br_lg,
  },
  countdownInner: {
    height: "94.64%",
    top: "7.66%",
    bottom: "-2.3%",
    left: "0%",
    right: "0%",
  },
  text: {
    top: "26.82%",
    left: "39.23%",
    fontSize: 100,
    color: Color.darkslategray_200,
    position: "absolute",
    textAlign: "center",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    fontWeight: "600",
  },
  textTimer:{
    top: "33.82%",
    left: "22.23%",
    fontSize: 60,
    color: Color.darkslategray_200,
    position: "absolute",
    textAlign: "center",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    fontWeight: "600",
  },
  countdown: {
    height: 261,
    flex: 1,
  },
  countdownWrapper: {
    paddingHorizontal: Padding.p_10xl,
    paddingVertical: Padding.p_8xs,
    flexDirection: "row",
  },
  rememberIfYou: {
    fontSize: FontSize.titleH4MontserratSemibold22_size,
    flex: 1,
  },
  rememberIfYouTouchYourPhWrapper: {
    height: 163,
    paddingVertical: 34,
    paddingHorizontal: Padding.p_8xl,
    alignSelf: "stretch",
    flexDirection: "row",
    overflow: "hidden",
  },
  frameParent: {
    flex: 1,
  },
  playersCountdown7: {
    backgroundColor: Color.hiFiBackgroundGradient,
    flexDirection: "row",
    width: "100%",
    overflow: "hidden",
  },
});

export default Game;
