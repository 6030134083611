import * as React from "react";
import { useEffect,useState } from "react";
import { useAppContext } from "../utils/AppContext";
import { API, graphqlOperation } from 'aws-amplify';
import { createPaymentIntent } from "../src/graphql/mutations";
import { ExpressCheckoutElement, useStripe, useElements } from '@stripe/react-stripe-js';

const Checkout = () => {

    const stripe = useStripe();

    const [clientSecret, setClientSecret] = useState("");
    const elements = useElements();

    const {gameAmount} = useAppContext();

    const [url, setUrl] = useState('');

    useEffect(() => {
      const url = window.location.href;
      setUrl(url);
      }, []);      

    useEffect(() => {
      setTimeout(pIntent, 1500);
      }, []);
    



      const pIntent = async () => {
        try {
            const paymentInput = {amount: gameAmount, currency: 'gbp'}
            const createdPayment = await API.graphql(graphqlOperation(createPaymentIntent, {input: paymentInput}));
  
            const { clientSecret } = createdPayment.data.createPaymentIntent;
            const { id } = createdPayment.data.createPaymentIntent;
            setClientSecret(clientSecret);
          } catch (error) {
            console.error('Failed to fetch payment intent:', error);
          }
          return clientSecret;
        };

      const onClick = ({resolve}) => {
        const options = {
          emailRequired: true
        };
        resolve(options);
      };

      const onConfirm = async () => {
            const {error} = await stripe.confirmPayment({
              // `Elements` instance that's used to create the Express Checkout Element.
              elements,
              // `clientSecret` from the created PaymentIntent
              clientSecret: clientSecret,
              confirmParams: {
                return_url: url,
              },
              // Uncomment below if you only want redirect for redirect-based payments.
              // redirect: 'if_required',
            });
            if (error) {
              // This point is reached only if there's an immediate error when confirming the payment. Show the error to your customer (for example, payment details incomplete).
              console.log (error);
            } else {
              // Your customer will be redirected to your `return_url`.
            }
        }

    return (
            <ExpressCheckoutElement
                    onClick={onClick}
                    onConfirm={onConfirm}
                />
    );
}


export default Checkout