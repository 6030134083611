import React, { createContext, useContext, useState } from 'react';

interface AppContextType {
  name: string;
  setName: (name: string) => void;
  gameID: string;
  setGameID: (gameID: string) => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  gameAmount: number;
  setGameAmount: (gameAmount: number ) => void;
  gameDuration: number;
  setGameDuration: React.Dispatch<React.SetStateAction<number>>;
  payment: boolean;
  setPayment: (payment: boolean) => void;
  gameURL: string;
  setGameURL: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  playerID: string;
  setPlayerID: (gameID: string) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

interface AppProviderProps {
    children: React.ReactNode;
  }
  

export const AppProvider = ({children}: AppProviderProps) => {
  const [name, setName] = useState('');
  const [gameID, setGameID] = useState('');
  const [email, setEmail] = useState('');
  const [gameAmount, setGameAmount] = useState(5);
  let [gameURL, setGameURL] = useState('');
  let [gameDuration, setGameDuration] = useState(0);
  const [payment, setPayment] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [playerID, setPlayerID] = useState('');

  const contextValue: AppContextType = {
    name,
    setName,
    gameID,
    setGameID,
    email,
    setEmail,
    gameAmount,
    setGameAmount,
    gameURL,
    setGameURL,
    gameDuration,
    setGameDuration,
    payment,
    setPayment,
    phoneNumber,
    setPhoneNumber,
    playerID,
    setPlayerID,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};
