// MobXStore.ts
import { observable, action, makeObservable } from 'mobx';
import React, { createContext } from 'react';

class MobXStore {
  @observable name = '';
  @observable gameID = '';
  @observable email = '';
  @observable phoneNumber = '';
  @observable amount = 5;
  @observable playerID ='';
  @observable gameDuration = 0;
  @observable gameURL ='';
  @observable payment = false ;

  constructor() {
    makeObservable(this);
  }

  @action setName(newName: string) {
    this.name = newName;
  }

  @action setGameID(newGameID: string) {
    this.gameID = newGameID;
  }

  @action setEmail(newEmail: string) {
    this.email = newEmail;
  }

  @action setPhoneNumber(newPhoneNumber: string) {
    this.phoneNumber = newPhoneNumber;
  }

  @action setAmount(newAmount: number) {
    this.amount = newAmount;
  }

  @action setPlayerID(newPlayerID: string) {
    this.playerID = newPlayerID;
  }

  @action setGameDuration(newGameDuration: number) {
    this.gameDuration = newGameDuration;
  }

  @action setGameURL(newSetGameURL: string) {
    this.gameURL = newSetGameURL;
  }

  @action setPayment(newPayment: boolean) {
    this.payment = newPayment;
  }
}

const store = new MobXStore();

// Define a context and provider component
const MobXContext = createContext<MobXStore | undefined>(undefined);

interface AppProviderProps {
    children: React.ReactNode;
  }

// Create a MobXProvider component to wrap your app with
export const MobXProvider = ({children}: AppProviderProps)  => {
    return <MobXContext.Provider value={store}>{children}</MobXContext.Provider>;
  };
  
// Export MobXContext for useContext usage
export { MobXContext };


export default store;
