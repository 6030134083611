import * as React from "react";
import { useMemo } from "react";
import {
  Image,
  StyleSheet,
  Text,
  View,
  ImageSourcePropType,
} from "react-native";
import {
  Margin,
  FontSize,
  FontFamily,
  Color,
  Border,
  Padding,
} from "../GlobalStyles";

type MasterButtonType = {
  buttonText?: string;
  iconshome?: ImageSourcePropType;
  iconshome1?: ImageSourcePropType;

  /** Style props */
  masterButtonHeight?: number | string;
  buttonTextDisplay?: string;
  buttonTextAlignItems?: string;
  buttonTextJustifyContent?: string;
  buttonTextWidth?: number | string;
};

const getStyleValue = (key: string, value: string | number | undefined) => {
  if (value === undefined) return;
  return { [key]: value === "unset" ? undefined : value };
};

const MasterButton = ({
  buttonText,
  masterButtonHeight,
  buttonTextDisplay,
  buttonTextAlignItems,
  buttonTextJustifyContent,
  buttonTextWidth,
  iconshome,
  iconshome1,
}: MasterButtonType) => {
  const masterButtonStyle = useMemo(() => {
    return {
      ...getStyleValue("height", masterButtonHeight),
    };
  }, [masterButtonHeight]);

  const buttonTextStyle = useMemo(() => {
    return {
      ...getStyleValue("display", buttonTextDisplay),
      ...getStyleValue("alignItems", buttonTextAlignItems),
      ...getStyleValue("justifyContent", buttonTextJustifyContent),
      ...getStyleValue("width", buttonTextWidth),
    };
  }, [
    buttonTextDisplay,
    buttonTextAlignItems,
    buttonTextJustifyContent,
    buttonTextWidth,
  ]);

  return (
    <View style={[styles.masterButton, masterButtonStyle]}>
      <Image style={styles.iconshome} resizeMode="cover" source={iconshome} />
      <Text style={[styles.buttonText, styles.ml8, buttonTextStyle]}>
        {buttonText}
      </Text>
      <Image
        style={[styles.iconshome, styles.ml8]}
        resizeMode="cover"
        source={iconshome1}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  ml8: {
    // marginLeft: Margin.m_3xs,
  },
  iconshome: {
    width: 24,
    height: 24,
    display: "none",
  },
  buttonText: {
    fontSize: FontSize.titleH5MontserratSemibold20_size,
    fontWeight: "600",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    color: Color.hiFiBlackAndWhiteWhite,
    textAlign: "center",
  },
  masterButton: {
    flex: 1,
    flexWrap: 'wrap',
    borderRadius: Border.br_xs,
    backgroundColor: Color.hiFiMainColorsGreen,
    flexDirection: "row",
    paddingHorizontal: Padding.p_13xl,
    paddingVertical: Padding.p_md,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default MasterButton;
