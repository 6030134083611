import * as React from "react";
import { useMemo, useState } from "react";
import { Text, StyleSheet, TextInput, View } from "react-native";
import {Picker} from '@react-native-picker/picker';
import {
  Margin,
  FontFamily,
  FontSize,
  Color,
  Border,
  Padding,
} from "../GlobalStyles";

type NameInputCardType = {
  inputType?: "name" | "email" | "phone";
  nameInput?: string;
  emailInput?: string;
  defaultInput?: boolean;
  onChangeText?: (text: string) => void;
  value?:string;
  selectedCountryCode?: string; // Include selectedCountryCode in the type definition
  setSelectedCountryCode?: React.Dispatch<React.SetStateAction<string>> | undefined

  /** Style props */
  propMarginTop?: number | string;
};

const getStyleValue = (key: string, value: string | number | undefined) => {
  if (value === undefined) return;
  return { [key]: value === "unset" ? undefined : value };
};

const NameInputCard = ({
  inputType,
  nameInput,
  emailInput,
  defaultInput,
  onChangeText,
  propMarginTop,
  value,
  selectedCountryCode,
  setSelectedCountryCode
}: NameInputCardType) => {
  // const [ selectedCountryCode, setSelectedCountryCode] = useState('UK');
  
  const inputFieldDefaultStyle = useMemo(() => {
    return {
      ...getStyleValue("marginTop", propMarginTop),
    };
  }, [propMarginTop]);
  const pickerStyles = {
    // Define your styles for the Picker component here
    // For example:
    borderRadius: Border.br_xs,
    backgroundColor: Color.hiFiMainColorsLightBlue,
    borderStyle: "solid",
    borderColor: "#eaf2f4",
    borderWidth: 1,
    flexDirection: "row",
    paddingHorizontal: Padding.p_6xs,
    paddingVertical: Padding.p_2xs,
    alignSelf: "stretch",
    marginTop: Margin.m_7xs
  };
  

  return (
    <View style={[styles.inputFielddefault, inputFieldDefaultStyle]}>
      <View style={styles.inputFielddefault}>
        <Text style={[styles.email, styles.emailTypo]}>{nameInput}</Text>
        {inputType === "phone" ? (
          <View style={styles.phoneInputContainer}>
            <Picker
              selectedValue={selectedCountryCode}
              // may need to change the verification of the item value here
              onValueChange={(itemValue) => setSelectedCountryCode(itemValue)}
              style={pickerStyles}
            >
              <Picker.Item label="United Kingdom (+44)" value="UK" />
              <Picker.Item label="United States (+1)" value="US" />
              <Picker.Item label="Canada (+1)" value="CA" />
            </Picker>
            <TextInput
              style={[styles.inputMain, styles.mt4]}
              keyboardType="phone-pad"
              defaultValue={value}
              onChangeText={onChangeText}
              placeholder=""
            />
          </View>
        ) : (
        <TextInput
          style={[styles.inputMain, styles.mt4]}
          keyboardType={emailInput}
          secureTextEntry={defaultInput}
          defaultValue={value}
          onChangeText={onChangeText}
        />
        )}
        <Text style={[styles.aidText, styles.mt4, styles.emailTypo]}>
          Aid Text
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mt4: {
    marginTop: Margin.m_7xs,
  },
  emailTypo: {
    textAlign: "left",
    fontFamily: FontFamily.titleH3MontserratRegular28,
    fontSize: FontSize.labelAidTextMontserratRegular16_size,
    alignSelf: "stretch",
  },
  email: {
    color: Color.darkslategray_300,
  },
  inputMain: {
    borderRadius: Border.br_xs,
    backgroundColor: Color.hiFiMainColorsLightBlue,
    borderStyle: "solid",
    borderColor: "#eaf2f4",
    borderWidth: 1,
    flexDirection: "row",
    paddingHorizontal: Padding.p_6xs,
    paddingVertical: Padding.p_2xs,
    alignSelf: "stretch",
  },
  aidText: {
    color: Color.loFiGray4,
    display: "none",
  },
  inputFielddefault: {
    alignSelf: "stretch",
  },
  phoneInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default NameInputCard;
