/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpdateGameById = /* GraphQL */ `subscription OnUpdateGameById($id: ID!) {
  onUpdateGameById(id: $id) {
    id
    status
    gameTime
    playerCount
    amountPerPlayer
    losers
    winners
    initiator
    gamePlayers {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGameByIdSubscriptionVariables,
  APITypes.OnUpdateGameByIdSubscription
>;
export const onCreatePlayer = /* GraphQL */ `subscription OnCreatePlayer(
  $filter: ModelSubscriptionPlayerFilterInput
  $email: String
) {
  onCreatePlayer(filter: $filter, email: $email) {
    id
    cognitoUserId
    email
    name
    exceededThreshold
    games {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    balance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePlayerSubscriptionVariables,
  APITypes.OnCreatePlayerSubscription
>;
export const onUpdatePlayer = /* GraphQL */ `subscription OnUpdatePlayer(
  $filter: ModelSubscriptionPlayerFilterInput
  $email: String
) {
  onUpdatePlayer(filter: $filter, email: $email) {
    id
    cognitoUserId
    email
    name
    exceededThreshold
    games {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    balance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePlayerSubscriptionVariables,
  APITypes.OnUpdatePlayerSubscription
>;
export const onDeletePlayer = /* GraphQL */ `subscription OnDeletePlayer(
  $filter: ModelSubscriptionPlayerFilterInput
  $email: String
) {
  onDeletePlayer(filter: $filter, email: $email) {
    id
    cognitoUserId
    email
    name
    exceededThreshold
    games {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    balance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePlayerSubscriptionVariables,
  APITypes.OnDeletePlayerSubscription
>;
export const onCreateGameSession = /* GraphQL */ `subscription OnCreateGameSession(
  $filter: ModelSubscriptionGameSessionFilterInput
  $email: String
) {
  onCreateGameSession(filter: $filter, email: $email) {
    id
    status
    gameTime
    playerCount
    amountPerPlayer
    losers
    winners
    initiator
    gamePlayers {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGameSessionSubscriptionVariables,
  APITypes.OnCreateGameSessionSubscription
>;
export const onUpdateGameSession = /* GraphQL */ `subscription OnUpdateGameSession(
  $filter: ModelSubscriptionGameSessionFilterInput
  $email: String
) {
  onUpdateGameSession(filter: $filter, email: $email) {
    id
    status
    gameTime
    playerCount
    amountPerPlayer
    losers
    winners
    initiator
    gamePlayers {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGameSessionSubscriptionVariables,
  APITypes.OnUpdateGameSessionSubscription
>;
export const onDeleteGameSession = /* GraphQL */ `subscription OnDeleteGameSession(
  $filter: ModelSubscriptionGameSessionFilterInput
  $email: String
) {
  onDeleteGameSession(filter: $filter, email: $email) {
    id
    status
    gameTime
    playerCount
    amountPerPlayer
    losers
    winners
    initiator
    gamePlayers {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGameSessionSubscriptionVariables,
  APITypes.OnDeleteGameSessionSubscription
>;
export const onCreatePlayerGame = /* GraphQL */ `subscription OnCreatePlayerGame(
  $filter: ModelSubscriptionPlayerGameFilterInput
  $email: String
) {
  onCreatePlayerGame(filter: $filter, email: $email) {
    id
    playerId
    gameSessionId
    player {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    gameSession {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePlayerGameSubscriptionVariables,
  APITypes.OnCreatePlayerGameSubscription
>;
export const onUpdatePlayerGame = /* GraphQL */ `subscription OnUpdatePlayerGame(
  $filter: ModelSubscriptionPlayerGameFilterInput
  $email: String
) {
  onUpdatePlayerGame(filter: $filter, email: $email) {
    id
    playerId
    gameSessionId
    player {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    gameSession {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePlayerGameSubscriptionVariables,
  APITypes.OnUpdatePlayerGameSubscription
>;
export const onDeletePlayerGame = /* GraphQL */ `subscription OnDeletePlayerGame(
  $filter: ModelSubscriptionPlayerGameFilterInput
  $email: String
) {
  onDeletePlayerGame(filter: $filter, email: $email) {
    id
    playerId
    gameSessionId
    player {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    gameSession {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePlayerGameSubscriptionVariables,
  APITypes.OnDeletePlayerGameSubscription
>;
