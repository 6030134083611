import React, {useState, useEffect, useContext} from "react";
import { View, Image, StyleSheet, Text, ScrollView, Pressable, TouchableOpacity, Alert, Modal } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import NameInputCard from "../components/NameInputCard";
import { Margin, FontSize, FontFamily, Color, Padding, Border } from "../GlobalStyles";
import {useNavigate, redirect, useParams, Route, RouteProps} from 'react-router-dom';
import {Auth, API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { CreatePlayerInput, CreatePlayerMutation, GetGameSessionQuery, NameByEmailQuery } from "../src/API";
import { getGameSession } from "../src/graphql/queries";
import * as queries from "../src/graphql/queries";
import * as mutations from "../src/graphql/mutations";
import { createPlayer } from "../src/graphql/mutations";
import { useAppContext } from "../utils/AppContext";
import { DeviceMotion } from 'expo-sensors';


const JoinGame = () => {
  // const navigation = useNavigation();  
  const navigate = useNavigate();

  const {gameID , setGameID, phoneNumber, setPhoneNumber, email, setEmail, playerID, setPlayerID} = useAppContext();
  let { gameDuration, setGameDuration, gameURL, setGameURL, setGameAmount, gameAmount} = useAppContext();

  // const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');
  const [user, setUser] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('UK'); 
  const [permissionStatus, setPermissionStatus] = React.useState<string | null>(null);
  
  const handlePhoneNumberChange = (text: string) => {
    // Remove any non-digit characters from the input
    const cleanedText = text.replace(/\D/g, '');
  
    // Determine the country code based on the selectedCountryCode
    let countryCode = '';
    if (selectedCountryCode === 'US') {
      countryCode = '+1';
    } else if (selectedCountryCode === 'UK') {
      countryCode = '+44';
    } else if (selectedCountryCode === 'CA') {
      countryCode = '+1';
    } // Add more country codes as needed
  
    // Combine the country code and cleaned input
    const fullPhoneNumber = countryCode + cleanedText;
  
    // Set the combined value in the phoneNumber state
    setPhoneNumber(fullPhoneNumber);
  };

  console.log("Phone: ", phoneNumber);


  const [accelButtonVisible, setAccelButtonVisible] = useState(true);
  const [confirmButtonvisible, setConfirmButtonVisible] = useState(false);

  const [confirmationCode, setConfirmationCode] = useState('');

  const [modalVisible, setModalVisible] = useState(false);


  //do useEffect to get the url of the page, so you have the gameID

  // do query checks on the gameID to ensure, it is in onboarding, if not then issue with progressing with game.

  useEffect(() => {
    const url = window.location.href;
    const searchString = "session/";
    const startIndex = url.indexOf(searchString);

    const urlString = new URL(url);
    const baseUrl = urlString.protocol + '//' + urlString.hostname;

    
    setGameURL(baseUrl);
    gameURL = baseUrl;

    console.log("The base url is: ", baseUrl);

    console.log("The game url is: ", gameURL);

    if (startIndex !== -1) {
      const result = url.substring(startIndex + searchString.length);
      console.log("Result: ",result);
      setGameID(result); 
      findGame(result);
      getGameDetails(result);
    } else {
      console.log("Game not found");
    }
  }, []); 

  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.httpEquiv = 'Permissions-Policy';
    metaTag.content = 'accelerometer=()';
    document.getElementsByTagName('head')[0].appendChild(metaTag);

    return () => {
      document.getElementsByTagName('head')[0].removeChild(metaTag);
    };
  }, []);

  const findGame = async (gameID: string) =>{
      // const getGameDetails = await API.graphql<GraphQLQuery<GetGameSessionQuery>>(graphqlOperation(getGameSession, { id: gameID }))
      const getGameDetails = await API.graphql<GraphQLQuery<GetGameSessionQuery>>({
        query: queries.getGameSession,
        variables: { id: gameID },
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      });

      const gameStatus = getGameDetails.data?.getGameSession?.status;
      console.log("The game status is: ", gameStatus);
      const gameLength = Number(getGameDetails.data?.getGameSession?.gameTime);
      gameDuration = gameLength;
      console.log("The game duration is: ", gameLength);
      console.log("Game duration: ", gameDuration);
      if(gameLength) {
        setGameDuration(gameLength);
        console.log("Game duration: ", gameDuration);
      }
  }

  const getGameDetails = async (gameID: string) => {
    try {
      // const getGameDetails = await API.graphql<GraphQLQuery<GetGameSessionQuery>>(graphqlOperation(getGameSession, { id: gameID }))
      const getGameDetails = await API.graphql<GraphQLQuery<GetGameSessionQuery>>({
        query: queries.getGameSession,
        variables: { id: gameID },
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      });

      if(getGameDetails){
        const gameStatus = getGameDetails.data?.getGameSession?.status;
        console.log("Game status from mutation: ",gameStatus);
        const amountPerUser = Number(getGameDetails.data?.getGameSession?.amountPerPlayer);
        gameAmount = amountPerUser;
        console.log("The amount per user: ", amountPerUser);
        if(amountPerUser){
          setGameAmount(amountPerUser);
        }
        console.log("Game amount from mutation 2: ",gameAmount);
      }
    } catch (error) {
      console.log ("There has been an error", error);
    }
  }


  // Function to generate a random password
const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-+=';
    let password = '';
    const passwordLength = 12; // Desired password length
  
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
    return password;
  };
  

  function getAccel(): void {
    if (typeof DeviceMotionEvent !== 'undefined' && typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission().then((response: PermissionState) => {
        if (response === 'granted') {
          console.log("accelerometer permission granted");
          setAccelButtonVisible(false);
          setConfirmButtonVisible(true);
          // Do stuff here
        }
      });
    }
  }

  function getAccess(): void {
    if (typeof navigator.permissions !== 'undefined' && typeof navigator.permissions.query === 'function') {
      // Permissions API is available, try to request motion access permission
      navigator.permissions.query({ name: 'accelerometer' as PermissionName })
        .then((result) => {
          if (result.state === 'granted') {
            console.log('Motion access permission granted');
            // Do stuff here when permission is granted
              setAccelButtonVisible(false);
              setConfirmButtonVisible(true);
          } else if (result.state === 'prompt') {
            console.log('Motion access permission prompt');
            // Permission prompt is shown
          } else {
            console.log('Motion access permission denied');
            // Handle denied permission
          }
        })
        .catch((error) => {
          console.error('Error requesting motion access permission:', error);
          // Handle errors here
        });
    } else {
      console.log("Device doesn't support motion access.");
      // Fallback for devices that don't support the Permissions API
      // You can add alternative code or messages here
    }
  }

  // const isSafari = navigator.userAgent.includes('Safari');
  // const isSafari = 'WebkitAppearance' in document.documentElement.style;
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);


  const requestMotionPermission = async () => {
    if(isChrome) {
      // const { status } = await DeviceMotion.requestPermissionsAsync();
      // setAccelButtonVisible(false);
      // setConfirmButtonVisible(true);
      // useMotion();
      getAccess();
      // setPermissionStatus(status);
    } else {
      getAccel();
    }
  };


  
  const handleSignUp = async () => {
    try {
    const password = generateRandomPassword();  
      const response = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name: name,
          family_name: lastName,
          email: email,          // optional
          phone_number: phoneNumber   // optional - E.164 number convention
          // other custom attributes 
        },
        autoSignIn: { // optional - enables auto sign in after user is confirmed
          enabled: true,
        }
      });
      setUser(response.userSub);
      setModalVisible(true);
    } catch (error) {
      console.log('Error signing up:', error);
    }
  };
  

// Delete User
const deleteUser = async () => {
    try {
      setModalVisible(false);
      const result = await Auth.deleteUser();
      console.log(result);
    } catch (error) {
      console.log('Error deleting user', error);
      setModalVisible(false);
    }
  }


  const saveData = () => {
    sessionStorage.setItem('gameIDKey', gameID);
    sessionStorage.setItem('emailKey', email);
    sessionStorage.setItem('gameDurationKey', gameDuration.toString());
    sessionStorage.setItem('nameKey', name);
    sessionStorage.setItem('phoneKey', phoneNumber);
  };

  const confirmSignUp = async () => {
    try {
      const result = await Auth.confirmSignUp(email, confirmationCode);
      if(result === "SUCCESS"){
        try {
          // Create mutation for player if statement needed
          const player: CreatePlayerInput = {cognitoUserId: user, email: email, exceededThreshold: false, name: name}
          // const createdPlayer = await API.graphql<GraphQLQuery<CreatePlayerMutation>>(graphqlOperation(createPlayer, {input: player}));

          const createdPlayer = await API.graphql<GraphQLQuery<CreatePlayerMutation>>({
            query: mutations.createPlayer,
            variables: {input: player},
            authMode: GRAPHQL_AUTH_MODE.API_KEY
          });
          console.log("Created Player object: ", createdPlayer);
          const id = createdPlayer.data?.createPlayer?.id;
          if(id != null){
            setPlayerID(id);
            sessionStorage.setItem('playerIDKey', id);
            saveData();
            // currentAuthenticatedUser();
          }
          } catch (error) 
          {
            console.log('Error creating player', error);
          }
      } else {
        alert("There has been an issue");
      }
      setModalVisible(false);
      navigate("/OnboardingRules");
      alert("You are signed up to play, please pay to join");
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }




  return (
    <LinearGradient
      style={[styles.signUp, styles.parentFlexBox]}
      locations={[0, 0.22, 1]}
      colors={["#c9dee3", "#c9dee3", "#94bec7"]}
    >
      <View style={[styles.iphoneTopPropertiesParent, styles.parentFlexBox]}>
        <Image
          style={styles.iphoneTopProperties}
          resizeMode="cover"
          // source={require("../assets/iphone-top-properties.png")}
        />
        {/* <Text style={[styles.present, styles.mt8]}>present.</Text> */}
          <Text style={[styles.createAnAccount, styles.mt8]}>Confirm information to join the game</Text>
          <View style={[styles.inputFielddefaultParent, styles.mt4]}>
            <NameInputCard 
              nameInput="First name" 
              emailInput="default" 
              onChangeText={setName}
              value={name}
            />
            <NameInputCard 
              nameInput="Last name" 
              emailInput="default" 
              onChangeText={setLastName}
              value={lastName} 
              propMarginTop={24}
            />
            <NameInputCard
              nameInput="Email"
              emailInput="email-address"
              onChangeText={setEmail}
              value={email}
              propMarginTop={24}
            />
              <NameInputCard
                inputType = "phone"
                nameInput="Phone Number"
                // emailInput="phone-pad"
                onChangeText={handlePhoneNumberChange}
                value={phoneNumber}
                propMarginTop={24}
                selectedCountryCode={selectedCountryCode}
                setSelectedCountryCode={setSelectedCountryCode} // Pass the selectedCountryCode prop
              />

            <Text style={[styles.diclaimerText]}>*Important* We require motion access to know if you touched your phone. You only need to do this once.</Text>
            {permissionStatus && <Text style={[styles.diclaimerText]}>Permission status: {permissionStatus}</Text>}
          <TouchableOpacity 
          style={[
            styles.masterButtonDisabled,
            styles.largeButtonsNoIcons,
            styles.mt20,
            styles.rectangleParentFlexBox,
            accelButtonVisible ? styles.masterButtonActive:null
          ]}
          onPress={requestMotionPermission}  
          // onPress={()=> DeviceMotion.requestPermissionsAsync()} 
          // onPress={motionPermission}  
          >
            <Text style={[styles.buttonTextDisabled, accelButtonVisible? styles.buttonTextActive:null]}>Motion Access</Text>
          </TouchableOpacity>
          </View>

          <Modal visible={modalVisible} animationType="slide" transparent>
              <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                  <Text style={styles.modalTitle}>Please enter the code that was sent to your email</Text>
                  <NameInputCard
                    nameInput="Email Confirmation Code"
                    emailInput="phone-pad"
                    onChangeText={setConfirmationCode}
                    value={confirmationCode}
                    propMarginTop={24}
                    />
                  {/* <Button title="Sign Up" onPress={handleSignUp} /> */}
                  <View style={styles.buttonContainer}>
                    <TouchableOpacity
                      style={[styles.button, styles.cancelButton]}
                      onPress={deleteUser}
                    >
                      <Text style={[styles.buttonText, styles.cancelButtonText]}>Cancel</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[styles.button, styles.confirmationButton]}
                      onPress={confirmSignUp}
                    >
                      <Text style={[styles.buttonText, styles.confirmButtonText]}>Confirm Sign Up</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>  
           <View style={[styles.inputFielddefaultParent]}>
           <Pressable
              style={[
                styles.masterButtonDisabled,
                styles.largeButtonsNoIcons,
                styles.mt20,
                styles.rectangleParentFlexBox,
                confirmButtonvisible ? styles.masterButtonActive:null
              ]}
              onPress={handleSignUp}
          >
            <Text style={[styles.buttonTextDisabled, confirmButtonvisible? styles.buttonTextActive:null]}>Continue</Text>
          </Pressable>
            </View> 
          </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  mt24: {
    marginTop: Margin.m_6xl,
  },
  mt4: {
    marginTop: Margin.m_7xs,
  },
  frameScrollViewContent: {
    flexDirection: "column",
    paddingTop: 18,
  },
  mt8: {
    marginTop: Margin.m_3xs,
  },
  parentFlexBox: {
    overflow: "hidden",
    flex: 1,
  },
  iphoneTopProperties: {
    width: 375,
    height: 44,
  },
  present: {
    fontSize: FontSize.size_4xl,
    fontWeight: "900",
    fontFamily: FontFamily.notoSerifBlack,
    color: Color.darkslategray_300,
    textAlign: "left",
  },
  createAnAccount: {
    marginBottom: Margin.m_6xl,
    fontSize: FontSize.titleH3MontserratRegular28_size,
    fontWeight: "600",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    color: Color.hiFiMainColorsDarkBlue,
    width: 312,
    textAlign: "left",
  },
  inputFielddefaultParent: {
    paddingHorizontal: Padding.p_md,
    paddingVertical: 0,
    alignSelf: "stretch",
  },
  iconseyeOpen: {
    width: 24,
    height: 24,
    display: "none",
  },
  createAnAccountParent: {
    alignSelf: "stretch",
  },
  iphoneTopPropertiesParent: {
    paddingBottom: 22,
    alignItems: "center",
    alignSelf: "stretch",
  },
  signUp: {
    width: "100%",
    height: 821,
    flexDirection: "row",
    backgroundColor: Color.hiFiBackgroundGradient,
  },
  ml8: {
    marginLeft: Margin.m_3xs,
  },
  mt20: {
    marginTop: Margin.m_3xl,
  },
  rectangleParentFlexBox: {
    paddingHorizontal: Padding.p_md,
    flexDirection: "row",
    alignItems: "center",
  },
  pleaseClickHere: {
    fontSize: FontSize.labelAidTextMontserratBold12_size,
    color: Color.darkslategray_300,
    textAlign: "left",
    display: "flex",
    width: 317,
    fontFamily: FontFamily.titleH3MontserratRegular28,
    alignItems: "center",
  },
  rectangleParent: {
    paddingVertical: Padding.p_8xs,
    overflow: "hidden",
  },
  largeButtonsNoIcons: {
    paddingVertical: 0,
    justifyContent: "center",
    alignSelf: "stretch",
  },
  alreadyHaveAn: {
    fontFamily: FontFamily.titleH3MontserratRegular28,
  },
  login: {
    fontWeight: "700",
    fontFamily: FontFamily.labelAidTextMontserratBold12,
  },
  alreadyHaveAnContainer: {
    fontSize: FontSize.labelAidTextMontserratRegular16_size,
    color: Color.hiFiMainColorsDarkBlue,
    textAlign: "center",
  },
  frameParent: {
    paddingHorizontal: 0,
    paddingVertical: Padding.p_12xs,
    alignItems: "center",
    overflow: "hidden",
    alignSelf: "stretch",
  },
  confirmButton : {
    marginTop: Margin.m_4xs ,
    height: 50,
    color: "fff",
    fontSize: FontSize.size_sm,
    fontWeight: "400",
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 8,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 4,
  },
  cancelButton: {
    backgroundColor: Color.errorRed,
  },
  confirmationButton: {
    backgroundColor: Color.hiFiMainColorsGreen,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cancelButtonText: {
    color: 'white',
  },
  confirmButtonText: {
    color: 'white',
  },
  masterButtonActive: {
    borderRadius: Border.br_xs,
    backgroundColor: Color.hiFiMainColorsGreen,
    // paddingHorizontal: Padding.p_13xl,
    paddingVertical: Padding.p_md,
    flex: 1,
  },
  masterButtonDisabled: {
    borderRadius: Border.br_xs,
    backgroundColor: Color.hiFiDenotativeColorsDisabledGray,
    // paddingHorizontal: Padding.p_13xl,
    paddingVertical: Padding.p_md,
    flex: 1,
  },
  buttonTextDisabled: {
    fontSize: FontSize.titleH5MontserratSemibold20_size,
    textAlign: "center",
  },
  buttonTextActive : {
    fontSize: FontSize.titleH5MontserratSemibold20_size,
    fontWeight: "600",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    color: Color.hiFiBlackAndWhiteWhite,
    textAlign: "center",
  },
  diclaimerText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop:10
  },
  phoneInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default JoinGame;
