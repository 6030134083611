import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkout from './Checkout';
import { useAppContext } from '../utils/AppContext';
import {STRIPE_KEY } from "@env"

const PaymentCollection = () => {

    const stripePromise = loadStripe(STRIPE_KEY);

    const {gameAmount} = useAppContext ();

    const options = {
      mode:'payment',
      amount: gameAmount*100,
      currency: 'gbp',
    };

   return (
    <Elements stripe={stripePromise} options={options}>
        <Checkout />
    </Elements>
   ); 
}


export default PaymentCollection;