// const Stack = createNativeStackNavigator();
import * as React from "react";
import * as ReactDOM from 'react-dom';
// import { NavigationContainer } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { BrowserRouter as Router, Route, RouteProps, Routes, createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { useFonts } from "expo-font";
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import JoinGame from "./screens/JoinGame";
import OnboardingRules from "./screens/OnboardingRules";
import InitialAnimationWin from "./screens/InitialAnimationWin";
import InitialAnimationLoss from "./screens/InitialAnimationLoss";
import GameOverview from "./screens/GameOverview";
import Game from "./screens/Game";
import Home from "./screens/Home";
import {Amplify} from 'aws-amplify';
import awsconfig from './src/aws-exports';
import { AppProvider } from "./utils/AppContext";
import { MobXProvider } from './utils/MobXStore';

// type RootStackParamList = {
//   Home: undefined;
// }

Amplify.configure({
  ...awsconfig,
  Analytics: {
    disabled: true,
  },
});

// type JoinGameProps = {
//   route: RouteProps
// }

// {route}:JoinGameProps

const App = () => {

  // const [fontsLoaded, error] = useFonts({
  //   Montserrat: require("./assets/fonts/Montserrat.ttf"),
  //   Montserrat_regular: require("./assets/fonts/Montserrat_regular.ttf"),
  //   Montserrat_medium: require("./assets/fonts/Montserrat_medium.ttf"),
  //   Montserrat_semibold: require("./assets/fonts/Montserrat_semibold.ttf"),
  //   // Montserrat_bold: require("./assets/fonts/Montserrat_bold.ttf"),
  //   Montserrat_medium_italic: require("./assets/fonts/Montserrat_medium_italic.ttf"),
  //   "Noto Serif": require("./assets/fonts/Noto_Serif.ttf"),
  //   "Noto Serif_black": require("./assets/fonts/Noto_Serif_black.ttf"),
  //   // "SF Pro Text": require("./assets/fonts/SF_Pro_Text_Regular.otf"),
  //   "SF Pro Text_semibold": require("./assets/fonts/SF_Pro_Text_Semibold.otf"),
  // });

  // if (!fontsLoaded && !error) {
  //   console.log(error, "an error occured");
  //   // Double check and revert back
  //   return null;
  // }


  // const router = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <Home />,
  //     children: [
  //       {
  //         path: "session/:id",
  //         element: <JoinGame />,
  //       },
  //       {
  //         path: "onboardingrules",
  //         element: <OnboardingRules />,
  //       },
  //       {
  //         path: "game",
  //         element: <Game />,
  //       },
  //       {
  //         path: "loss",
  //         element: <InitialAnimationLoss />,
  //       },
  //       {
  //         path: "won",
  //         element: <InitialAnimationWin />,
  //       },
  //       {
  //         path: "/overview",
  //         element: <GameOverview />,
  //       },
  //     ],
  //   },
  // ]);
  
  return (
    <AppProvider>
      <MobXProvider>
        <Router>
            <Routes>
              <Route path="/session/:id" element={<JoinGame/>} />
              <Route path="/OnboardingRules" element={<OnboardingRules/>} />
              <Route path="/game" element={<Game/>} />
              <Route path="/loss" element={<InitialAnimationLoss/>} />
              <Route path="/won" element={<InitialAnimationWin/>} />
              <Route path="/overview" element={<GameOverview/>} />
              <Route path="/" element={<Home/>} />
            </Routes>
        </Router>
      </MobXProvider>
    </AppProvider>
  );
}

export default App;