import * as React from "react";
import { useEffect } from "react";
import {
  ScrollView,
  Image,
  StyleSheet,
  View,
  Text,
  Pressable,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
// import { useNavigation, RouteProp } from "@react-navigation/native";
// import { StackNavigationProp } from "@react-navigation/stack";
import MasterButton from "../components/MasterButton";
import { Margin, Padding, FontSize, FontFamily, Color } from "../GlobalStyles";
import ConfettiCannon from 'react-native-confetti-cannon';
import { useNavigate, Navigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { CreateSMSMessageInput, SendSMSMutation } from "../src/API";
import { sendSMS } from "../src/graphql/mutations";
import { GraphQLQuery } from '@aws-amplify/api';

// type RootStackParamList = {
//   Home:undefined;
//   InitialAnimationWin: {itemId: number};
// }

// type InitialAnimationWinProp = RouteProp<RootStackParamList, 'InitialAnimationWin'>;
 
// type InitialAnimationWinNavigationProp = StackNavigationProp<
//   RootStackParamList,
//   'InitialAnimationWin'
// >;

// type Props = {
//   route: InitialAnimationWinProp;
//   navigation: InitialAnimationWinNavigationProp;
// };

const InitialAnimationWin = () => {
  // const navigation = useNavigation();

  const navigate = useNavigate();

  // useEffect(() => {
  //   navigation.setOptions({
  //     gestureEnabled: false,
  //     });
  //   }, [navigation]);

  let nameKey = sessionStorage.getItem('nameKey') ?? '';
  let phoneNumberKey = sessionStorage.getItem('phoneKey') ?? '';

  useEffect(() => {
    // sendMessage()
    setTimeout(sendMessage, 1500);
  }, []);

    const sendMessage = async () => {
      try {
        const smsInput: CreateSMSMessageInput = {phoneNumber: phoneNumberKey, name: nameKey, winStatus: true}
        const createdSMS = await API.graphql<GraphQLQuery<SendSMSMutation>>(graphqlOperation(sendSMS, {input: smsInput}));
        console.log ("SMS function triggered: ", createdSMS.data?.sendSMS?.result);
      } catch (error) {
        console.error('Failed to send SMS', error);
      }
    }

  const handleNavigation = () =>{
    return <Navigate to='/overview' />
  }

  return (
    <LinearGradient
      style={styles.initialAnimationwin}
      locations={[0, 0.22, 1]}
      colors={["#c9dee3", "#c9dee3", "#94bec7"]}
    >
      <ScrollView
        style={[styles.iphoneTopPropertiesParent, styles.frameParentFlexBox]}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <ConfettiCannon count={200} origin={{x: -10, y: 0}} fallSpeed={3500} fadeOut={true} />
        <Image
          style={styles.iphoneTopProperties}
          resizeMode="cover"
          // source={require("../assets/frame-3.png")}
        />
        <View style={[styles.frameParent, styles.frameParentFlexBox]}>
        {/* <View style={[styles.presentWrapper, styles.presentWrapperFlexBox]}>
          <Text style={styles.present}>present.</Text>
        </View> */}
          <View style={[styles.frameWrapper, styles.frameParentFlexBox]}>
            <View style={styles.frame}>
              <View style={styles.frame1}>
                <Image
                  style={styles.setIcon}
                  resizeMode="cover"
                  source={require("../assets/set.png")}
                />
              </View>
            </View>
          </View>
          <View
            style={[
              styles.youWon50Wrapper,
              styles.mt7,
              styles.youWon50WrapperFlexBox,
            ]}
          >
            <Text style={styles.youWon50}>You won the game!</Text>
          </View>
          <View
            style={[
              styles.largeButtonsNoIconsWrapper,
              styles.mt7,
              styles.frameParentFlexBox,
            ]}
          >
            <Pressable
              style={[
                styles.largeButtonsNoIcons,
                styles.youWon50WrapperFlexBox,
              ]}
              onPress={() => navigate("/overview", { replace: true })}
              // onPress={handleNavigation}
            >
              <MasterButton
                buttonText="View Results"
                iconshome={require("../assets/iconshome19.png")}
                iconshome1={require("../assets/iconshome19.png")}
              />
            </Pressable>
          </View>
        </View>
      </ScrollView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  mt7: {
    marginTop: Margin.m_4xs,
  },
  frameScrollViewContent: {
    flexDirection: "column",
  },
  frameParentFlexBox: {
    alignSelf: "stretch",
    overflow: "hidden",
  },
  youWon50WrapperFlexBox: {
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  iphoneTopProperties: {
    width: 375,
    height: 44,
  },
  setIcon: {
    width: 233,
    height: 272,
  },
  frame1: {
    position: "absolute",
    marginTop: -136,
    top: "50%",
    right: "0%",
    left: "0%",
    flexDirection: "row",
    width: "100%",
  },
  frame: {
    height: 272,
    overflow: "hidden",
    flex: 1,
  },
  frameWrapper: {
    paddingHorizontal: Padding.p_11xl,
    paddingVertical: Padding.p_7xl,
    flexDirection: "row",
  },
  youWon50: {
    fontSize: FontSize.titleH3MontserratRegular28_size,
    fontWeight: "600",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    color: Color.hiFiMainColorsDarkBlue,
    textAlign: "center",
    flex: 1,
  },
  youWon50Wrapper: {
    height: 88,
    paddingHorizontal: Padding.p_3xl,
    paddingVertical: Padding.p_xl,
    overflow: "hidden",
  },
  largeButtonsNoIcons: {
    alignItems: "center",
  },
  largeButtonsNoIconsWrapper: {
    paddingHorizontal: Padding.p_8xs,
    paddingVertical: Padding.p_2xl,
  },
  frameParent: {
    height: 649,
    paddingTop: 47,
    paddingBottom: 99,
  },
  iphoneTopPropertiesParent: {
    flex: 1,
  },
  initialAnimationwin: {
    alignItems: "flex-end",
    backgroundColor: Color.hiFiBackgroundGradient,
    overflow: "hidden",
    width: "100%",
    flex: 1,
  },
  present: {
    fontSize: FontSize.size_4xl,
    fontWeight: "900",
    fontFamily: FontFamily.notoSerifBlack,
    color: Color.loFiBlack,
    textAlign: "left",
  },
  presentWrapper: {
    height: 38,
    paddingHorizontal: 54,
    // paddingVertical: Padding.p_13xs,
    overflow: "hidden",
  },
  presentWrapperFlexBox: {
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "stretch",
  }
});

export default InitialAnimationWin;
