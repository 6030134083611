/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateGameSessionInput = {
  gameTime: number,
  playerCount: number,
  amountPerPlayer: number,
  status: GameStatus,
  initiator: string,
};

export enum GameStatus {
  REQUESTED = "REQUESTED",
  DECLINED = "DECLINED",
  ONBOARDING = "ONBOARDING",
  ACTIVE = "ACTIVE",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
}


export type GameData = {
  __typename: "GameData",
  id: string,
  gameTime: number,
  playerCount: number,
  amountPerPlayer: number,
  status: GameStatus,
  players:  Array<Player >,
  losers?:  Array<Player | null > | null,
  winners?:  Array<Player | null > | null,
};

export type Player = {
  __typename: "Player",
  id: string,
  cognitoUserId: string,
  email: string,
  name: string,
  exceededThreshold: boolean,
  games?: ModelPlayerGameConnection | null,
  balance?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelPlayerGameConnection = {
  __typename: "ModelPlayerGameConnection",
  items:  Array<PlayerGame | null >,
  nextToken?: string | null,
};

export type PlayerGame = {
  __typename: "PlayerGame",
  id: string,
  playerId: string,
  gameSessionId: string,
  player: Player,
  gameSession: GameSession,
  createdAt: string,
  updatedAt: string,
  email?: string | null,
};

export type GameSession = {
  __typename: "GameSession",
  id: string,
  status: GameStatus,
  gameTime: number,
  playerCount: number,
  amountPerPlayer: number,
  losers?: Array< string | null > | null,
  winners?: Array< string | null > | null,
  initiator: string,
  gamePlayers?: ModelPlayerGameConnection | null,
  createdAt: string,
  updatedAt: string,
  email?: string | null,
};

export type CreatePaymentIntentInput = {
  amount: number,
  currency: string,
};

export type PaymentIntent = {
  __typename: "PaymentIntent",
  id: string,
  clientSecret: string,
};

export type CreatePayoutInput = {
  losers: number,
  amountPerPlayer: number,
  gamePlayers: number,
  winners: number,
};

export type Payout = {
  __typename: "Payout",
  fullPayout: number,
};

export type CreateSMSMessageInput = {
  phoneNumber: string,
  name?: string | null,
  winStatus?: boolean | null,
};

export type SMSMessage = {
  __typename: "SMSMessage",
  result: string,
};

export type CreatePlayerInput = {
  cognitoUserId: string,
  name: string,
  email: string,
  exceededThreshold: boolean,
  balance?: number | null,
};

export type ModelPlayerConditionInput = {
  cognitoUserId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  exceededThreshold?: ModelBooleanInput | null,
  balance?: ModelFloatInput | null,
  and?: Array< ModelPlayerConditionInput | null > | null,
  or?: Array< ModelPlayerConditionInput | null > | null,
  not?: ModelPlayerConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdatePlayerInput = {
  id: string,
  cognitoUserId?: string | null,
  name?: string | null,
  exceededThreshold?: boolean | null,
  balance?: number | null,
};

export type DeletePlayerInput = {
  id: string,
};

export type ModelGameSessionConditionInput = {
  status?: ModelGameStatusInput | null,
  gameTime?: ModelFloatInput | null,
  playerCount?: ModelIntInput | null,
  amountPerPlayer?: ModelFloatInput | null,
  losers?: ModelStringInput | null,
  winners?: ModelStringInput | null,
  initiator?: ModelStringInput | null,
  and?: Array< ModelGameSessionConditionInput | null > | null,
  or?: Array< ModelGameSessionConditionInput | null > | null,
  not?: ModelGameSessionConditionInput | null,
};

export type ModelGameStatusInput = {
  eq?: GameStatus | null,
  ne?: GameStatus | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateGameSessionInput = {
  id: string,
  status?: GameStatus | null,
  gameTime?: number | null,
  playerCount?: number | null,
  amountPerPlayer?: number | null,
  losers?: Array< string | null > | null,
  winners?: Array< string | null > | null,
  initiator?: string | null,
};

export type DeleteGameSessionInput = {
  id: string,
};

export type CreatePlayerGameInput = {
  id?: string | null,
  playerId: string,
  gameSessionId: string,
};

export type ModelPlayerGameConditionInput = {
  playerId?: ModelIDInput | null,
  gameSessionId?: ModelIDInput | null,
  and?: Array< ModelPlayerGameConditionInput | null > | null,
  or?: Array< ModelPlayerGameConditionInput | null > | null,
  not?: ModelPlayerGameConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdatePlayerGameInput = {
  playerGameID: string,
  exceededThreshold?: boolean | null,
};

export type DeletePlayerGameInput = {
  id: string,
};

export type ModelPlayerFilterInput = {
  id?: ModelIDInput | null,
  cognitoUserId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  exceededThreshold?: ModelBooleanInput | null,
  balance?: ModelFloatInput | null,
  and?: Array< ModelPlayerFilterInput | null > | null,
  or?: Array< ModelPlayerFilterInput | null > | null,
  not?: ModelPlayerFilterInput | null,
};

export type ModelPlayerConnection = {
  __typename: "ModelPlayerConnection",
  items:  Array<Player | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelGameSessionFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelGameStatusInput | null,
  gameTime?: ModelFloatInput | null,
  playerCount?: ModelIntInput | null,
  amountPerPlayer?: ModelFloatInput | null,
  losers?: ModelStringInput | null,
  winners?: ModelStringInput | null,
  initiator?: ModelStringInput | null,
  and?: Array< ModelGameSessionFilterInput | null > | null,
  or?: Array< ModelGameSessionFilterInput | null > | null,
  not?: ModelGameSessionFilterInput | null,
};

export type ModelGameSessionConnection = {
  __typename: "ModelGameSessionConnection",
  items:  Array<GameSession | null >,
  nextToken?: string | null,
};

export type ModelPlayerGameFilterInput = {
  id?: ModelIDInput | null,
  playerId?: ModelIDInput | null,
  gameSessionId?: ModelIDInput | null,
  and?: Array< ModelPlayerGameFilterInput | null > | null,
  or?: Array< ModelPlayerGameFilterInput | null > | null,
  not?: ModelPlayerGameFilterInput | null,
};

export type ModelSubscriptionPlayerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cognitoUserId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  exceededThreshold?: ModelSubscriptionBooleanInput | null,
  balance?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionPlayerFilterInput | null > | null,
  or?: Array< ModelSubscriptionPlayerFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionGameSessionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  gameTime?: ModelSubscriptionFloatInput | null,
  playerCount?: ModelSubscriptionIntInput | null,
  amountPerPlayer?: ModelSubscriptionFloatInput | null,
  losers?: ModelSubscriptionStringInput | null,
  winners?: ModelSubscriptionStringInput | null,
  initiator?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGameSessionFilterInput | null > | null,
  or?: Array< ModelSubscriptionGameSessionFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPlayerGameFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  playerId?: ModelSubscriptionIDInput | null,
  gameSessionId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPlayerGameFilterInput | null > | null,
  or?: Array< ModelSubscriptionPlayerGameFilterInput | null > | null,
};

export type StartGameMutationVariables = {
  input: CreateGameSessionInput,
};

export type StartGameMutation = {
  startGame?:  {
    __typename: "GameData",
    id: string,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    status: GameStatus,
    players:  Array< {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    } >,
    losers?:  Array< {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    winners?:  Array< {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type CreatePaymentIntentMutationVariables = {
  input: CreatePaymentIntentInput,
};

export type CreatePaymentIntentMutation = {
  createPaymentIntent?:  {
    __typename: "PaymentIntent",
    id: string,
    clientSecret: string,
  } | null,
};

export type CreatePayoutMutationVariables = {
  input: CreatePayoutInput,
};

export type CreatePayoutMutation = {
  createPayout?:  {
    __typename: "Payout",
    fullPayout: number,
  } | null,
};

export type SendSMSMutationVariables = {
  input: CreateSMSMessageInput,
};

export type SendSMSMutation = {
  sendSMS?:  {
    __typename: "SMSMessage",
    result: string,
  } | null,
};

export type CreatePlayerMutationVariables = {
  input: CreatePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type CreatePlayerMutation = {
  createPlayer?:  {
    __typename: "Player",
    id: string,
    cognitoUserId: string,
    email: string,
    name: string,
    exceededThreshold: boolean,
    games?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePlayerMutationVariables = {
  input: UpdatePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type UpdatePlayerMutation = {
  updatePlayer?:  {
    __typename: "Player",
    id: string,
    cognitoUserId: string,
    email: string,
    name: string,
    exceededThreshold: boolean,
    games?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePlayerMutationVariables = {
  input: DeletePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type DeletePlayerMutation = {
  deletePlayer?:  {
    __typename: "Player",
    id: string,
    cognitoUserId: string,
    email: string,
    name: string,
    exceededThreshold: boolean,
    games?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGameSessionMutationVariables = {
  input: CreateGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type CreateGameSessionMutation = {
  createGameSession?:  {
    __typename: "GameSession",
    id: string,
    status: GameStatus,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    losers?: Array< string | null > | null,
    winners?: Array< string | null > | null,
    initiator: string,
    gamePlayers?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type UpdateGameSessionMutationVariables = {
  input: UpdateGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type UpdateGameSessionMutation = {
  updateGameSession?:  {
    __typename: "GameSession",
    id: string,
    status: GameStatus,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    losers?: Array< string | null > | null,
    winners?: Array< string | null > | null,
    initiator: string,
    gamePlayers?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type DeleteGameSessionMutationVariables = {
  input: DeleteGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type DeleteGameSessionMutation = {
  deleteGameSession?:  {
    __typename: "GameSession",
    id: string,
    status: GameStatus,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    losers?: Array< string | null > | null,
    winners?: Array< string | null > | null,
    initiator: string,
    gamePlayers?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type CreatePlayerGameMutationVariables = {
  input: CreatePlayerGameInput,
  condition?: ModelPlayerGameConditionInput | null,
};

export type CreatePlayerGameMutation = {
  createPlayerGame?:  {
    __typename: "PlayerGame",
    id: string,
    playerId: string,
    gameSessionId: string,
    player:  {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    gameSession:  {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type UpdatePlayerGameMutationVariables = {
  input: UpdatePlayerGameInput,
  condition?: ModelPlayerGameConditionInput | null,
};

export type UpdatePlayerGameMutation = {
  updatePlayerGame?:  {
    __typename: "PlayerGame",
    id: string,
    playerId: string,
    gameSessionId: string,
    player:  {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    gameSession:  {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type DeletePlayerGameMutationVariables = {
  input: DeletePlayerGameInput,
  condition?: ModelPlayerGameConditionInput | null,
};

export type DeletePlayerGameMutation = {
  deletePlayerGame?:  {
    __typename: "PlayerGame",
    id: string,
    playerId: string,
    gameSessionId: string,
    player:  {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    gameSession:  {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type GetPlayerQueryVariables = {
  id: string,
};

export type GetPlayerQuery = {
  getPlayer?:  {
    __typename: "Player",
    id: string,
    cognitoUserId: string,
    email: string,
    name: string,
    exceededThreshold: boolean,
    games?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPlayersQueryVariables = {
  filter?: ModelPlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlayersQuery = {
  listPlayers?:  {
    __typename: "ModelPlayerConnection",
    items:  Array< {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NameByEmailQueryVariables = {
  email: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NameByEmailQuery = {
  nameByEmail?:  {
    __typename: "ModelPlayerConnection",
    items:  Array< {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGameSessionQueryVariables = {
  id: string,
};

export type GetGameSessionQuery = {
  getGameSession?:  {
    __typename: "GameSession",
    id: string,
    status: GameStatus,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    losers?: Array< string | null > | null,
    winners?: Array< string | null > | null,
    initiator: string,
    gamePlayers?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type ListGameSessionsQueryVariables = {
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGameSessionsQuery = {
  listGameSessions?:  {
    __typename: "ModelGameSessionConnection",
    items:  Array< {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type InitiatorByGameQueryVariables = {
  id: string,
  initiator?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InitiatorByGameQuery = {
  initiatorByGame?:  {
    __typename: "ModelGameSessionConnection",
    items:  Array< {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlayerGameQueryVariables = {
  id: string,
};

export type GetPlayerGameQuery = {
  getPlayerGame?:  {
    __typename: "PlayerGame",
    id: string,
    playerId: string,
    gameSessionId: string,
    player:  {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    gameSession:  {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type ListPlayerGamesQueryVariables = {
  filter?: ModelPlayerGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlayerGamesQuery = {
  listPlayerGames?:  {
    __typename: "ModelPlayerGameConnection",
    items:  Array< {
      __typename: "PlayerGame",
      id: string,
      playerId: string,
      gameSessionId: string,
      player:  {
        __typename: "Player",
        id: string,
        cognitoUserId: string,
        email: string,
        name: string,
        exceededThreshold: boolean,
        games?:  {
          __typename: "ModelPlayerGameConnection",
          nextToken?: string | null,
        } | null,
        balance?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      gameSession:  {
        __typename: "GameSession",
        id: string,
        status: GameStatus,
        gameTime: number,
        playerCount: number,
        amountPerPlayer: number,
        losers?: Array< string | null > | null,
        winners?: Array< string | null > | null,
        initiator: string,
        gamePlayers?:  {
          __typename: "ModelPlayerGameConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlayerGamesByPlayerIdQueryVariables = {
  playerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlayerGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlayerGamesByPlayerIdQuery = {
  playerGamesByPlayerId?:  {
    __typename: "ModelPlayerGameConnection",
    items:  Array< {
      __typename: "PlayerGame",
      id: string,
      playerId: string,
      gameSessionId: string,
      player:  {
        __typename: "Player",
        id: string,
        cognitoUserId: string,
        email: string,
        name: string,
        exceededThreshold: boolean,
        games?:  {
          __typename: "ModelPlayerGameConnection",
          nextToken?: string | null,
        } | null,
        balance?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      gameSession:  {
        __typename: "GameSession",
        id: string,
        status: GameStatus,
        gameTime: number,
        playerCount: number,
        amountPerPlayer: number,
        losers?: Array< string | null > | null,
        winners?: Array< string | null > | null,
        initiator: string,
        gamePlayers?:  {
          __typename: "ModelPlayerGameConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlayerGamesByGameSessionIdQueryVariables = {
  gameSessionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlayerGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlayerGamesByGameSessionIdQuery = {
  playerGamesByGameSessionId?:  {
    __typename: "ModelPlayerGameConnection",
    items:  Array< {
      __typename: "PlayerGame",
      id: string,
      playerId: string,
      gameSessionId: string,
      player:  {
        __typename: "Player",
        id: string,
        cognitoUserId: string,
        email: string,
        name: string,
        exceededThreshold: boolean,
        games?:  {
          __typename: "ModelPlayerGameConnection",
          nextToken?: string | null,
        } | null,
        balance?: number | null,
        createdAt: string,
        updatedAt: string,
      },
      gameSession:  {
        __typename: "GameSession",
        id: string,
        status: GameStatus,
        gameTime: number,
        playerCount: number,
        amountPerPlayer: number,
        losers?: Array< string | null > | null,
        winners?: Array< string | null > | null,
        initiator: string,
        gamePlayers?:  {
          __typename: "ModelPlayerGameConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdateGameByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateGameByIdSubscription = {
  onUpdateGameById?:  {
    __typename: "GameSession",
    id: string,
    status: GameStatus,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    losers?: Array< string | null > | null,
    winners?: Array< string | null > | null,
    initiator: string,
    gamePlayers?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type OnCreatePlayerSubscriptionVariables = {
  filter?: ModelSubscriptionPlayerFilterInput | null,
  email?: string | null,
};

export type OnCreatePlayerSubscription = {
  onCreatePlayer?:  {
    __typename: "Player",
    id: string,
    cognitoUserId: string,
    email: string,
    name: string,
    exceededThreshold: boolean,
    games?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePlayerSubscriptionVariables = {
  filter?: ModelSubscriptionPlayerFilterInput | null,
  email?: string | null,
};

export type OnUpdatePlayerSubscription = {
  onUpdatePlayer?:  {
    __typename: "Player",
    id: string,
    cognitoUserId: string,
    email: string,
    name: string,
    exceededThreshold: boolean,
    games?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePlayerSubscriptionVariables = {
  filter?: ModelSubscriptionPlayerFilterInput | null,
  email?: string | null,
};

export type OnDeletePlayerSubscription = {
  onDeletePlayer?:  {
    __typename: "Player",
    id: string,
    cognitoUserId: string,
    email: string,
    name: string,
    exceededThreshold: boolean,
    games?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
  email?: string | null,
};

export type OnCreateGameSessionSubscription = {
  onCreateGameSession?:  {
    __typename: "GameSession",
    id: string,
    status: GameStatus,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    losers?: Array< string | null > | null,
    winners?: Array< string | null > | null,
    initiator: string,
    gamePlayers?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type OnUpdateGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
  email?: string | null,
};

export type OnUpdateGameSessionSubscription = {
  onUpdateGameSession?:  {
    __typename: "GameSession",
    id: string,
    status: GameStatus,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    losers?: Array< string | null > | null,
    winners?: Array< string | null > | null,
    initiator: string,
    gamePlayers?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type OnDeleteGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
  email?: string | null,
};

export type OnDeleteGameSessionSubscription = {
  onDeleteGameSession?:  {
    __typename: "GameSession",
    id: string,
    status: GameStatus,
    gameTime: number,
    playerCount: number,
    amountPerPlayer: number,
    losers?: Array< string | null > | null,
    winners?: Array< string | null > | null,
    initiator: string,
    gamePlayers?:  {
      __typename: "ModelPlayerGameConnection",
      items:  Array< {
        __typename: "PlayerGame",
        id: string,
        playerId: string,
        gameSessionId: string,
        player:  {
          __typename: "Player",
          id: string,
          cognitoUserId: string,
          email: string,
          name: string,
          exceededThreshold: boolean,
          balance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        gameSession:  {
          __typename: "GameSession",
          id: string,
          status: GameStatus,
          gameTime: number,
          playerCount: number,
          amountPerPlayer: number,
          losers?: Array< string | null > | null,
          winners?: Array< string | null > | null,
          initiator: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        email?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type OnCreatePlayerGameSubscriptionVariables = {
  filter?: ModelSubscriptionPlayerGameFilterInput | null,
  email?: string | null,
};

export type OnCreatePlayerGameSubscription = {
  onCreatePlayerGame?:  {
    __typename: "PlayerGame",
    id: string,
    playerId: string,
    gameSessionId: string,
    player:  {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    gameSession:  {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type OnUpdatePlayerGameSubscriptionVariables = {
  filter?: ModelSubscriptionPlayerGameFilterInput | null,
  email?: string | null,
};

export type OnUpdatePlayerGameSubscription = {
  onUpdatePlayerGame?:  {
    __typename: "PlayerGame",
    id: string,
    playerId: string,
    gameSessionId: string,
    player:  {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    gameSession:  {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};

export type OnDeletePlayerGameSubscriptionVariables = {
  filter?: ModelSubscriptionPlayerGameFilterInput | null,
  email?: string | null,
};

export type OnDeletePlayerGameSubscription = {
  onDeletePlayerGame?:  {
    __typename: "PlayerGame",
    id: string,
    playerId: string,
    gameSessionId: string,
    player:  {
      __typename: "Player",
      id: string,
      cognitoUserId: string,
      email: string,
      name: string,
      exceededThreshold: boolean,
      games?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      balance?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    gameSession:  {
      __typename: "GameSession",
      id: string,
      status: GameStatus,
      gameTime: number,
      playerCount: number,
      amountPerPlayer: number,
      losers?: Array< string | null > | null,
      winners?: Array< string | null > | null,
      initiator: string,
      gamePlayers?:  {
        __typename: "ModelPlayerGameConnection",
        items:  Array< {
          __typename: "PlayerGame",
          id: string,
          playerId: string,
          gameSessionId: string,
          createdAt: string,
          updatedAt: string,
          email?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      email?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    email?: string | null,
  } | null,
};
