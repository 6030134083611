/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const startGame = /* GraphQL */ `mutation StartGame($input: CreateGameSessionInput!) {
  startGame(input: $input) {
    id
    gameTime
    playerCount
    amountPerPlayer
    status
    players {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    losers {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    winners {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartGameMutationVariables,
  APITypes.StartGameMutation
>;
export const createPaymentIntent = /* GraphQL */ `mutation CreatePaymentIntent($input: CreatePaymentIntentInput!) {
  createPaymentIntent(input: $input) {
    id
    clientSecret
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentIntentMutationVariables,
  APITypes.CreatePaymentIntentMutation
>;
export const createPayout = /* GraphQL */ `mutation CreatePayout($input: CreatePayoutInput!) {
  createPayout(input: $input) {
    fullPayout
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePayoutMutationVariables,
  APITypes.CreatePayoutMutation
>;
export const sendSMS = /* GraphQL */ `mutation SendSMS($input: CreateSMSMessageInput!) {
  sendSMS(input: $input) {
    result
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendSMSMutationVariables,
  APITypes.SendSMSMutation
>;
export const createPlayer = /* GraphQL */ `mutation CreatePlayer(
  $input: CreatePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  createPlayer(input: $input, condition: $condition) {
    id
    cognitoUserId
    email
    name
    exceededThreshold
    games {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    balance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlayerMutationVariables,
  APITypes.CreatePlayerMutation
>;
export const updatePlayer = /* GraphQL */ `mutation UpdatePlayer(
  $input: UpdatePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  updatePlayer(input: $input, condition: $condition) {
    id
    cognitoUserId
    email
    name
    exceededThreshold
    games {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    balance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlayerMutationVariables,
  APITypes.UpdatePlayerMutation
>;
export const deletePlayer = /* GraphQL */ `mutation DeletePlayer(
  $input: DeletePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  deletePlayer(input: $input, condition: $condition) {
    id
    cognitoUserId
    email
    name
    exceededThreshold
    games {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    balance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlayerMutationVariables,
  APITypes.DeletePlayerMutation
>;
export const createGameSession = /* GraphQL */ `mutation CreateGameSession(
  $input: CreateGameSessionInput!
  $condition: ModelGameSessionConditionInput
) {
  createGameSession(input: $input, condition: $condition) {
    id
    status
    gameTime
    playerCount
    amountPerPlayer
    losers
    winners
    initiator
    gamePlayers {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGameSessionMutationVariables,
  APITypes.CreateGameSessionMutation
>;
export const updateGameSession = /* GraphQL */ `mutation UpdateGameSession(
  $input: UpdateGameSessionInput!
  $condition: ModelGameSessionConditionInput
) {
  updateGameSession(input: $input, condition: $condition) {
    id
    status
    gameTime
    playerCount
    amountPerPlayer
    losers
    winners
    initiator
    gamePlayers {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGameSessionMutationVariables,
  APITypes.UpdateGameSessionMutation
>;
export const deleteGameSession = /* GraphQL */ `mutation DeleteGameSession(
  $input: DeleteGameSessionInput!
  $condition: ModelGameSessionConditionInput
) {
  deleteGameSession(input: $input, condition: $condition) {
    id
    status
    gameTime
    playerCount
    amountPerPlayer
    losers
    winners
    initiator
    gamePlayers {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGameSessionMutationVariables,
  APITypes.DeleteGameSessionMutation
>;
export const createPlayerGame = /* GraphQL */ `mutation CreatePlayerGame(
  $input: CreatePlayerGameInput!
  $condition: ModelPlayerGameConditionInput
) {
  createPlayerGame(input: $input, condition: $condition) {
    id
    playerId
    gameSessionId
    player {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    gameSession {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlayerGameMutationVariables,
  APITypes.CreatePlayerGameMutation
>;
export const updatePlayerGame = /* GraphQL */ `mutation UpdatePlayerGame(
  $input: UpdatePlayerGameInput!
  $condition: ModelPlayerGameConditionInput
) {
  updatePlayerGame(input: $input, condition: $condition) {
    id
    playerId
    gameSessionId
    player {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    gameSession {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlayerGameMutationVariables,
  APITypes.UpdatePlayerGameMutation
>;
export const deletePlayerGame = /* GraphQL */ `mutation DeletePlayerGame(
  $input: DeletePlayerGameInput!
  $condition: ModelPlayerGameConditionInput
) {
  deletePlayerGame(input: $input, condition: $condition) {
    id
    playerId
    gameSessionId
    player {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    gameSession {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlayerGameMutationVariables,
  APITypes.DeletePlayerGameMutation
>;
