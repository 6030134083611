/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPlayer = /* GraphQL */ `query GetPlayer($id: ID!) {
  getPlayer(id: $id) {
    id
    cognitoUserId
    email
    name
    exceededThreshold
    games {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    balance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPlayerQueryVariables, APITypes.GetPlayerQuery>;
export const listPlayers = /* GraphQL */ `query ListPlayers(
  $filter: ModelPlayerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlayersQueryVariables,
  APITypes.ListPlayersQuery
>;
export const nameByEmail = /* GraphQL */ `query NameByEmail(
  $email: AWSEmail!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlayerFilterInput
  $limit: Int
  $nextToken: String
) {
  nameByEmail(
    email: $email
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NameByEmailQueryVariables,
  APITypes.NameByEmailQuery
>;
export const getGameSession = /* GraphQL */ `query GetGameSession($id: ID!) {
  getGameSession(id: $id) {
    id
    status
    gameTime
    playerCount
    amountPerPlayer
    losers
    winners
    initiator
    gamePlayers {
      items {
        id
        playerId
        gameSessionId
        player {
          id
          cognitoUserId
          email
          name
          exceededThreshold
          balance
          createdAt
          updatedAt
          __typename
        }
        gameSession {
          id
          status
          gameTime
          playerCount
          amountPerPlayer
          losers
          winners
          initiator
          createdAt
          updatedAt
          email
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGameSessionQueryVariables,
  APITypes.GetGameSessionQuery
>;
export const listGameSessions = /* GraphQL */ `query ListGameSessions(
  $filter: ModelGameSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listGameSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGameSessionsQueryVariables,
  APITypes.ListGameSessionsQuery
>;
export const initiatorByGame = /* GraphQL */ `query InitiatorByGame(
  $id: ID!
  $initiator: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGameSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  initiatorByGame(
    id: $id
    initiator: $initiator
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InitiatorByGameQueryVariables,
  APITypes.InitiatorByGameQuery
>;
export const getPlayerGame = /* GraphQL */ `query GetPlayerGame($id: ID!) {
  getPlayerGame(id: $id) {
    id
    playerId
    gameSessionId
    player {
      id
      cognitoUserId
      email
      name
      exceededThreshold
      games {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      balance
      createdAt
      updatedAt
      __typename
    }
    gameSession {
      id
      status
      gameTime
      playerCount
      amountPerPlayer
      losers
      winners
      initiator
      gamePlayers {
        items {
          id
          playerId
          gameSessionId
          createdAt
          updatedAt
          email
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    createdAt
    updatedAt
    email
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlayerGameQueryVariables,
  APITypes.GetPlayerGameQuery
>;
export const listPlayerGames = /* GraphQL */ `query ListPlayerGames(
  $filter: ModelPlayerGameFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlayerGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      playerId
      gameSessionId
      player {
        id
        cognitoUserId
        email
        name
        exceededThreshold
        games {
          nextToken
          __typename
        }
        balance
        createdAt
        updatedAt
        __typename
      }
      gameSession {
        id
        status
        gameTime
        playerCount
        amountPerPlayer
        losers
        winners
        initiator
        gamePlayers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlayerGamesQueryVariables,
  APITypes.ListPlayerGamesQuery
>;
export const playerGamesByPlayerId = /* GraphQL */ `query PlayerGamesByPlayerId(
  $playerId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPlayerGameFilterInput
  $limit: Int
  $nextToken: String
) {
  playerGamesByPlayerId(
    playerId: $playerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      playerId
      gameSessionId
      player {
        id
        cognitoUserId
        email
        name
        exceededThreshold
        games {
          nextToken
          __typename
        }
        balance
        createdAt
        updatedAt
        __typename
      }
      gameSession {
        id
        status
        gameTime
        playerCount
        amountPerPlayer
        losers
        winners
        initiator
        gamePlayers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlayerGamesByPlayerIdQueryVariables,
  APITypes.PlayerGamesByPlayerIdQuery
>;
export const playerGamesByGameSessionId = /* GraphQL */ `query PlayerGamesByGameSessionId(
  $gameSessionId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPlayerGameFilterInput
  $limit: Int
  $nextToken: String
) {
  playerGamesByGameSessionId(
    gameSessionId: $gameSessionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      playerId
      gameSessionId
      player {
        id
        cognitoUserId
        email
        name
        exceededThreshold
        games {
          nextToken
          __typename
        }
        balance
        createdAt
        updatedAt
        __typename
      }
      gameSession {
        id
        status
        gameTime
        playerCount
        amountPerPlayer
        losers
        winners
        initiator
        gamePlayers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        email
        __typename
      }
      createdAt
      updatedAt
      email
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlayerGamesByGameSessionIdQueryVariables,
  APITypes.PlayerGamesByGameSessionIdQuery
>;
