import * as React from "react";
import { useEffect } from "react";
import {
  Image,
  StyleSheet,
  Text,
  View,
  ScrollView,
  Pressable,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import MasterButton from "../components/MasterButton";
import { Margin, Padding, FontSize, FontFamily, Color } from "../GlobalStyles";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from '@aws-amplify/api';
import { CreateSMSMessageInput, SendSMSMutation } from "../src/API";
import { sendSMS } from "../src/graphql/mutations";

// type RootStackParamList = {
//   Home:undefined;
//   InitialAnimationLoss: {itemId: number};
// }

// type InitialAnimationLossProp = RouteProp<RootStackParamList, 'InitialAnimationLoss'>;
 
// type InitialAnimationLossNavigationProp = StackNavigationProp<
//   RootStackParamList,
//   'InitialAnimationLoss'
// >;


// type Props = {
//   route: InitialAnimationLossProp;
//   navigation: InitialAnimationLossNavigationProp;
// };

const InitialAnimationLoss = () => {

  const navigate = useNavigate();

  let nameKey = sessionStorage.getItem('nameKey') ?? '';
  let phoneNumberKey = sessionStorage.getItem('phoneKey') ?? '';

  useEffect(() => {
    // sendMessage()
    setTimeout(sendMessage, 1500);
  }, []);

    const sendMessage = async () => {
      try {
        const smsInput: CreateSMSMessageInput = {phoneNumber: phoneNumberKey, name: nameKey, winStatus: false}
        const createdSMS = await API.graphql<GraphQLQuery<SendSMSMutation>>(graphqlOperation(sendSMS, {input: smsInput}));
        console.log ("SMS function triggered: ", createdSMS.data?.sendSMS?.result);
      } catch (error) {
        console.error('Failed to send SMS', error);
      }
    }

  // useEffect(() => {
  //   navigation.setOptions({
  //     gestureEnabled: false,
  //     });
  //   }, [navigation]);  

  return (
    <LinearGradient
      style={styles.initialAnimationloss}
      locations={[0, 0.22, 1]}
      colors={["#c9dee3", "#c9dee3", "#94bec7"]}
    >
      <Image
        style={[styles.iphoneTopProperties, styles.frameFlexBox]}
        resizeMode="cover"
        // source={require("../assets/iphone-top-properties7.png")}
      />
      <View style={[styles.frameParent, styles.frameFlexBox]}>
        <View style={[styles.presentWrapper, styles.presentWrapperFlexBox]}>
          <Text style={styles.present}>present.</Text>
        </View>
        <View style={[styles.frameChild, styles.mt10, styles.frameFlexBox]} />
      </View>
      <ScrollView
        style={[styles.frameGroup, styles.frameFlexBox]}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <View
          style={[styles.image1111Parent, styles.image1111ParentSpaceBlock]}
        >
          <Image
            style={styles.image1111}
            resizeMode="cover"
            source={require("../assets/image-11-1-1.png")}
          />
          <Text style={[styles.youLost10, styles.mt11, styles.youFlexBox]}>
            You lost £5!
          </Text>
          <Text
            style={[
              styles.oopsYouTouchedContainer,
              styles.mt11,
              styles.youFlexBox,
            ]}
          >
            <Text style={styles.oopsYouTouchedContainer1}>
              <Text
                style={styles.oopsYouTouched}
              >{`OOPS!! You touched your phone! `}</Text>
              <Text style={styles.oopsYouTouched}>
                You weren’t present. Your money
              </Text>
              <Text style={styles.willGoTo}> will go to the winner(s).</Text>
            </Text>
          </Text>
        </View>
        <View
          style={[
            styles.largeButtonsNoIconsWrapper,
            styles.mt7,
            styles.image1111ParentSpaceBlock,
          ]}
        >
          <Pressable
            style={[styles.largeButtonsNoIcons, styles.presentWrapperFlexBox]}
            onPress={() => navigate("/overview")}
          >
            <MasterButton
              buttonText="View Results"
              // iconshome={require("../assets/iconshome13.png")}
              // iconshome1={require("../assets/iconshome13.png")}
            />
          </Pressable>
        </View>
      </ScrollView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  mt10: {
    marginTop: Margin.m_xs,
  },
  mt11: {
    marginTop: Margin.m_sm,
  },
  mt7: {
    marginTop: Margin.m_4xs,
  },
  frameScrollViewContent: {
    flexDirection: "column",
  },
  frameFlexBox: {
    alignSelf: "stretch",
    overflow: "hidden",
  },
  presentWrapperFlexBox: {
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  image1111ParentSpaceBlock: {
    paddingHorizontal: Padding.p_8xs,
    alignSelf: "stretch",
    overflow: "hidden",
  },
  youFlexBox: {
    textAlign: "center",
    alignSelf: "stretch",
  },
  iphoneTopProperties: {
    maxWidth: "100%",
    height: 44,
    width: "100%",
  },
  present: {
    fontSize: FontSize.size_4xl,
    fontWeight: "900",
    fontFamily: FontFamily.notoSerifBlack,
    color: Color.loFiBlack,
    textAlign: "left",
  },
  presentWrapper: {
    height: 78,
    paddingHorizontal: 134,
    paddingVertical: Padding.p_md,
    overflow: "hidden",
  },
  frameChild: {
    height: 700,
  },
  frameParent: {
    height: 59,
    paddingHorizontal: Padding.p_13xs,
    paddingBottom: Padding.p_9xl,
    alignItems: "center",
  },
  image1111: {
    width: 318,
    height: 303,
  },
  youLost10: {
    fontSize: FontSize.titleH3MontserratRegular28_size,
    fontWeight: "600",
    fontFamily: FontFamily.titleH5MontserratSemibold20,
    color: Color.hiFiMainColorsDarkBlue,
  },
  oopsYouTouched: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  willGoTo: {
    margin: Margin.m_11xs,
  },
  oopsYouTouchedContainer1: {
    lineBreak: "anywhere",
  },
  oopsYouTouchedContainer: {
    fontSize: FontSize.size_lg,
    fontFamily: FontFamily.titleH3MontserratRegular28,
    color: Color.darkslategray_300,
    display: "flex",
    height: 77,
    alignItems: "center",
  },
  image1111Parent: {
    paddingTop: Padding.p_12xs,
    alignItems: "center",
  },
  largeButtonsNoIcons: {
    alignItems: "center",
  },
  largeButtonsNoIconsWrapper: {
    paddingTop: Padding.p_8xs,
    paddingBottom: Padding.p_2xl,
  },
  frameGroup: {
    flex: 1,
  },
  initialAnimationloss: {
    height: 813,
    backgroundColor: Color.hiFiBackgroundGradient,
    alignItems: "center",
    overflow: "hidden",
    width: "100%",
    flex: 1,
  },
});

export default InitialAnimationLoss;
